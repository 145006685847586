import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { producers_end_point } from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";

export const producerSettingUpdate = createAsyncThunk(
  "producerSettingUpdate",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        producers_end_point.Producer_profile_update,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const passwordUpdate = createAsyncThunk(
  "passwordUpdate",
  async (user) => {
    try {
      let res;

      res = await axiosInstance.post(producers_end_point.passwordUpdate, user);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  message: null,
  updateProfileStatus: "idle",
  profileUpdated: false,
  passwordUpdateStatus: "idle",
  passwordUpdated: false,
};

const DashboardSettingSlice = createSlice({
  name: "dashboard_setting",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders.addCase(producerSettingUpdate.pending, (state) => {
      state.updateProfileStatus = "loading";
      state.profileUpdated = false;
    });
    builders.addCase(producerSettingUpdate.fulfilled, (state, { payload }) => {
      state.updateProfileStatus = "idle";
      state.message = payload?.message;
      if (payload?.status === 200) {
        toast(state.message, { type: "success" });
        state.profileUpdated = true;
      } else {
        toast(state.message, { type: "error" });
        state.profileUpdated = false;
      }
    });
    builders
      .addCase(producerSettingUpdate.rejected, (state) => {
        state.updateProfileStatus = "idle";
        state.message = "something went wrong";
      })

      //pass update
      .addCase(passwordUpdate.pending, (state) => {
        state.passwordUpdateStatus = "loading";
        state.message = null;
      })
      .addCase(passwordUpdate.fulfilled, (state, { payload }) => {
        state.passwordUpdateStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.passwordUpdated = true;

          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(passwordUpdate.rejected, (state, action) => {
        state.passwordUpdateStatus = "idle";
        state.message = "Something went wrong";
      });
  },
});

export default DashboardSettingSlice.reducer;
