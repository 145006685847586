import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { auth_end_points } from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";
import privateAxiosInstance from "../../../Api/AxiosInstance";

export const SignUpRequest = createAsyncThunk("sign_up", async (user) => {
  try {
    let res;

    if (user?.role === "user") {
      res = await privateAxiosInstance.post(auth_end_points.userSignUp, user);
    } else {
      res = await privateAxiosInstance.post(
        auth_end_points.producerSignUp,
        user
      );
    }

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const SignUpRequest_producer = createAsyncThunk(
  "sign_up_producer",
  async (user) => {
    try {
      let res;

      res = await privateAxiosInstance.post(
        auth_end_points.producerSignUp,
        user
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const producerPasswordSetup = createAsyncThunk(
  "producerPasswordSetup",
  async (data) => {
    try {
      let res;

      res = await privateAxiosInstance.post("/producer/set/password", data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const FbSignUp_user = createAsyncThunk(
  "sign_up_facebook_user",
  async (data) => {
    try {
      let temp = "/user/producerFacebookRegister";
      let res;
      if (data.role === "user") {
        res = await privateAxiosInstance.post(
          auth_end_points.fbSignUp_user,
          data
        );
      } else {
        res = await privateAxiosInstance.post(temp, data);
      }

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const VerifyOtp = createAsyncThunk("verify", async (info) => {
  try {
    const res = await axiosInstance.post(auth_end_points.verifyAccount, info);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const ResendOtp = createAsyncThunk("resendOtp", async (data) => {
  try {
    const res = await privateAxiosInstance.post(auth_end_points.resendOtp, {
      email: data,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  status: "idle",
  isSignupCompleted: false,
  verifyRequest: false,
  verified: false,
  message: null,
  signUp_fb: false,
  user: {},
  res_status: null,
  FbSignUp_user_status: "idle",
  VerifyOtp_status: "idle",
  redirectTo: "/",
  SignUpRequest_producer_status: "idle",
  SignUpRequest_producer_data: null,
  SignUpRequest_producer_err: null,

  producerPasswordSetup_status: "idle",
  producerPasswordSetup_data: null,
  producerPasswordSetup_err: null,
};
export const SignUpSlice = createSlice({
  name: "signUpSlice",

  initialState,
  reducers: {
    reset_verifyRequest: (state, { payload }) => {
      state.verifyRequest = payload;
    },
    reset_SignUpRequest_producer_data: (state, { payload }) => {
      state.SignUpRequest_producer_data = payload;
    },
    reset_varified: (state, { payload }) => {
      state.verified = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //producer password set up
      .addCase(producerPasswordSetup.pending, (state) => {
        state.producerPasswordSetup_status = "loading";
        state.producerPasswordSetup_err = null;
      })
      .addCase(producerPasswordSetup.fulfilled, (state, { payload }) => {
        state.producerPasswordSetup_status = "idle";
        state.verifyRequest = "false";

        state.res_status = payload?.status;
        if (payload?.status === 200) {
          state.verified = true;
          toast.success(payload?.message);
          sessionStorage.clear();
          localStorage.setItem("token", payload?.token);
        } else {
          toast.error(payload?.message);
        }
        state.message = payload?.message;
      })
      .addCase(producerPasswordSetup.rejected, (state, action) => {
        state.producerPasswordSetup_status = "idle";
        state.message = "Something went wrong";
      })

      //producer account set up--
      .addCase(SignUpRequest_producer.pending, (state) => {
        state.SignUpRequest_producer_status = "loading";
        state.SignUpRequest_producer_err = null;
        state.SignUpRequest_producer_data = null;
      })
      .addCase(SignUpRequest_producer.fulfilled, (state, { payload }) => {
        state.SignUpRequest_producer_status = "idle";
        // state.message = payload?.message;
        if (payload?.status === 200) {
          state.SignUpRequest_producer_data = payload?.data;
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
        // state.message = payload?.message;
      })
      .addCase(SignUpRequest_producer.rejected, (state, action) => {
        state.status = "idle";
        state.message = "Something went wrong";
        toast.error("Something went wrong");
      })

      //user sign up
      .addCase(SignUpRequest.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.res_status = null;
      })
      .addCase(SignUpRequest.fulfilled, (state, { payload }) => {
        state.status = "idle";
        // state.message = payload?.message;
        if (payload?.status === 200) {
          state.verifyRequest = true;
          state.res_status = 200;
          localStorage.setItem("token", payload?.token);
          sessionStorage.setItem("email", payload?.data?.email);
          state.user = {
            email: payload?.data?.email,
          };
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
        // state.message = payload?.message;
      })
      .addCase(SignUpRequest.rejected, (state, action) => {
        state.status = "idle";
        state.message = "Something went wrong";
      })
      .addCase(VerifyOtp.pending, (state) => {
        state.VerifyOtp_status = "loading";
        state.message = null;
      })
      .addCase(VerifyOtp.fulfilled, (state, { payload }) => {
        state.VerifyOtp_status = "idle";
        state.verifyRequest = "false";

        state.res_status = payload?.status;
        if (payload?.status === 200) {
          state.verified = true;

         
          let temp = {
            cover_img: payload?.data?.cover_img,
            email: payload?.data?.email,
            full_name: payload?.data?.full_name,
            isActive: payload?.data?.isActive,
            isMeetingPermission: payload?.data?.isMeetingPermission,
            isSignupCompleted: payload?.data?.isSignupCompleted,
            phone: payload?.data?.phone,
            profile_image: payload?.data?.profile_image,
            register_type: payload?.data?.register_type,
            role: payload?.data?.role,
            // stripeAccountId: payload?.data?.cover_img,
            // stripeCustomerId: payload?.data?.cover_img,
            stripeSubscriptionId: payload?.data?.stripeSubscriptionId,
            trial_end: payload?.data?.trial_end,
            _id: payload?.data?._id,
            stripeCustomerId: payload?.data?.stripeAccountId,
          };
          // temp = {
          //   ...temp,
          //   stripeCustomerId: payload?.data?.stripeAccountId,
          // };
          state.userDetails = temp;

          localStorage.setItem("userDetails", JSON.stringify(temp));

          sessionStorage.clear();
          // localStorage.setItem("token", payload?.token);
        }
        state.message = payload?.message;
      })
      .addCase(VerifyOtp.rejected, (state, action) => {
        state.VerifyOtp_status = "idle";
        state.message = "Something went wrong";
      })
      .addCase(ResendOtp.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.res_status = null;
      })
      .addCase(ResendOtp.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        state.res_status = payload?.status;
      })
      .addCase(ResendOtp.rejected, (state, action) => {
        state.status = "idle";
        state.message = "Something went wrong";
      })

      .addCase(FbSignUp_user.pending, (state) => {
        state.FbSignUp_user_status = "loading";
        state.message = null;
        state.res_status = null;
        state.redirectTo = "";
        state.signUp_fb = false;
      })
      .addCase(FbSignUp_user.fulfilled, (state, { payload }) => {
        state.FbSignUp_user_status = "idle";
        if (payload?.status === 200) {
          state.signUp_fb = true;
          if (payload?.userRole?.role === "producers") {
            state.redirectTo = "/user-authentication/payment-setup";
            //   : (window.location.pathname = "/dashboard");
          } else {
            state.redirectTo = "/";
            // state.redirectTo="/"
          }

          localStorage.setItem("token", payload?.token);
        } else {
          toast(payload?.message, { type: "error" });
        }
        state.message = payload?.message;
        // state.res_status = payload?.status;
      })
      .addCase(FbSignUp_user.rejected, (state, action) => {
        state.FbSignUp_user_status = "idle";
        state.message = "Something went wrong";
      });
  },
});

export const {
  reset_verifyRequest,
  reset_varified,
  reset_SignUpRequest_producer_data,
} = SignUpSlice.actions;
export default SignUpSlice.reducer;
