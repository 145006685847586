import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  invite_member_end_point,
  meetingPage_end_point,
} from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";

export const userCreateMeeting = createAsyncThunk(
  "userMeetingCreate",
  async (data) => {
    try {
      // const res = await axiosInstance.post(
      //   meetingPage_end_point.createMeeting,
      //   data
      // );
      const res = await axiosInstance.post(
        meetingPage_end_point.createMeeting,
        data
      );

      return res.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const initialInviteList = createAsyncThunk(
  "initialInviteList",
  async () => {
    try {
      const res = await axiosInstance.get(invite_member_end_point.allUserList);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const inviteMemberById = createAsyncThunk(
  "inviteProducers_",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        invite_member_end_point.invite_mem,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getParticipentInfo = createAsyncThunk(
  "participentInfo",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.participentInfo,
        { result: data }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getRequestParticipentInfo = createAsyncThunk(
  "getRequestParticipentInfo",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.participentInfo,
        { result: data }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchMeetingDetails_user = createAsyncThunk(
  "fetchMeetingDetails_user",
  async (id) => {
    try {
      const res = await axiosInstance.post(
        `${meetingPage_end_point.meetingDetails}/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);


export const acceptInvite = createAsyncThunk("acceptInvite", async (data) => {
  try {
    const res = await axiosInstance.post(meetingPage_end_point.AcceptReq, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const rejectinvite = createAsyncThunk("rejectInvite", async (data) => {
  try {
    const res = await axiosInstance.post(meetingPage_end_point.RejectReq, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getMymeetings = createAsyncThunk("meetingList_user", async (data) => {
  try {
    const res = await axiosInstance.post(meetingPage_end_point.myList,data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const searchUserByEmailUser = createAsyncThunk(
  "searchUserByEmail_user",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.searchByEmail,
        data
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const initialState = {
  status: "idle",
  message: null,
  meetingCreated: false,
  data: null,
  initialList: null,
  initialListStatus: "idle",
  meetingId: null,
  meetingList: null,
  meetingListStatus: "idle",
  invited: false,
  participentInfo: null,
  requestparticipentInfo: [],
  accepted: false,
  acceptStatus: "idle",
  rejectStatus: "idle",
  rejected: false,
  storeInviteList: null,
  getMymeetings_pageTotal:0,
  meeetingDetails:null,
  meetingDetailsStatus:'idle',
  inviteUserStatus:'idle'
};
export const userMyMeetingSlice = createSlice({
  name: "userMymeeting",
  initialState,
  reducers: {
    handleStoreInviteList: (state, { payload }) => {
      state.storeInviteList = payload;
    },
    deleteStoreInviteList: (state, { payload }) => {
      state.storeInviteList?.splice(payload, 1);
    },
    setMeetingCreated: (state, { payload }) => {
      state.meetingCreated = payload;
    }
  },
  extraReducers: (builders) => {
    builders//
    .addCase(fetchMeetingDetails_user.pending, (state) => {
      state.meetingDetailsStatus = "loading";
      state.msg = null;
      state.meeetingDetails = null;
    })
    .addCase(
      fetchMeetingDetails_user.fulfilled,
      (state, { payload }) => {
        state.msg = payload?.message;

        state.meetingDetailsStatus = "idle";
        if (payload?.status === 200) {
          state.meeetingDetails = payload?.data;
        }
      }
    )
    .addCase(fetchMeetingDetails_user.rejected, (state) => {
      state.meetingDetailsStatus = "idle";
      state.msg = "something Went wrong";
    })
      //meeting creation
      .addCase(userCreateMeeting.pending, (state) => {
        state.status = "loading";
        state.meetingCreated = false;
        state.invited=false;
      })
      .addCase(userCreateMeeting.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          state.meetingCreated = true;
          // if (state.meetingList === null) {
          //   state.meetingList = payload?.data;
          // } else {
          //   state.meetingList?.push(payload?.data);
          // }
          state.meetingId = payload?.data?._id;
        }
        state.message = payload?.message;
      })
      .addCase(userCreateMeeting.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
      })
      //inital invite list
      .addCase(initialInviteList.pending, (state) => {
        state.initialListStatus = "loading";
      })
      .addCase(initialInviteList.fulfilled, (state, { payload }) => {
        state.initialListStatus = "idle";
        if (payload?.status === 200) {
          state.initialList =payload?.data?.data;
        }
        state.message = payload?.message;
      })
      .addCase(initialInviteList.rejected, (state, { payload }) => {
        state.initialListStatus = "idle";
        state.message = payload?.message;
      })
      //invite producers
      .addCase(inviteMemberById.pending, (state) => {
        state.inviteUserStatus = "loading";
        state.invited = false;
      })
      .addCase(inviteMemberById.fulfilled, (state, { payload }) => {
        state.inviteUserStatus = "idle";
        
        if (payload?.status === 200) {
          state.storeInviteList=null;
          state.invited = true;
          toast("Members are invited", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(inviteMemberById.rejected, (state, { payload }) => {
        state.inviteUserStatus = "idle";
        state.invited = false;
        state.message = payload?.message;
      })
      //meeting List
      .addCase(getMymeetings.pending, (state) => {
        state.meetingListStatus = "loading";
        state.invited=false
        
        
      })
      .addCase(getMymeetings.fulfilled, (state, { payload }) => {
        state.meetingListStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.meetingList = payload?.data;
          state.getMymeetings_pageTotal=payload?.pages
        }
      })
      .addCase(getMymeetings.rejected, (state, { payload }) => {
        state.meetingListStatus = "idle";
        state.message = payload?.message;
        state.invited = false;
        state.accepted = false;
        state.rejected = false;
      })
      //partcipent info
      .addCase(getParticipentInfo.pending, (state) => {
        state.status = "loading";
        state.joinStatus = false;
        state.participentInfo = null;
      })
      .addCase(getParticipentInfo.fulfilled, (state, { payload }) => {
        state.status = "idle";

        if (payload?.status === 200) {
          state.participentInfo = payload?.data;
        }
      })
      .addCase(getParticipentInfo.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = "something went wrong";
      })

      .addCase(getRequestParticipentInfo.pending, (state) => {
        state.status = "loading";
        state.joinStatus = false;
        state.requestparticipentInfo = null;
      })
      .addCase(getRequestParticipentInfo.fulfilled, (state, { payload }) => {
        state.status = "idle";

        if (payload?.status === 200) {
          state.requestparticipentInfo = payload?.data;
        }
      })
      .addCase(getRequestParticipentInfo.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
      //reject invite
      .addCase(rejectinvite.pending, (state) => {
        state.rejectStatus = "loading";
        state.resStatus = null;
        state.message = null;
        state.rejected = false;
      })
      .addCase(rejectinvite.fulfilled, (state, { payload }) => {
        state.rejectStatus = "idle";
        state.message = payload?.message;
        state.resStatus = payload?.status;
        if (payload?.status === 200) {
          state.rejected = true;
          state.meeetingDetails = payload?.data;
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(rejectinvite.rejected, (state, { payload }) => {
        state.rejectStatus = "idle";
        state.message = "something went wrong";
      })
      //accept invite
      .addCase(acceptInvite.pending, (state) => {
        state.acceptStatus = "loading";
        state.resStatus = null;
        state.message = null;
        state.accepted = false;
      })
      .addCase(acceptInvite.fulfilled, (state, { payload }) => {
        state.acceptStatus = "idle";
        state.message = payload?.message;
        state.resStatus = payload?.status;
        if (payload?.status === 200) {
          state.meeetingDetails = payload?.data;
          state.accepted = true;
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(acceptInvite.rejected, (state, { payload }) => {
        state.acceptStatus = "idle";
        state.message = "something went wrong";
      })
      //search user by email
      .addCase(searchUserByEmailUser.pending, (state) => {
        state.initialListStatus = "loading";
        state.initialList = null;
      })
      .addCase(searchUserByEmailUser.fulfilled, (state, { payload }) => {
        state.initialListStatus = "idle";

        if (payload?.status === 200) {
          state.initialList = payload?.data[0]?.data;
        } else {
          state.initialList = [];
        }
      })
      .addCase(searchUserByEmailUser.rejected, (state, { payload }) => {
        state.initialListStatus = "idle";
      });
  },
});
export const { handleStoreInviteList, deleteStoreInviteList,setMeetingCreated } =
  userMyMeetingSlice.actions;

export default userMyMeetingSlice.reducer;
