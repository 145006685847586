import moment from "moment";

export function InviteMembersArray(invitedUsers) {
  let spiltString;
  if (invitedUsers) {
    spiltString = invitedUsers.split(",");
  }
  return spiltString;
}

//GMT to normal time
export const GMT_to_normal_time = (time) => {
  return moment(time, ["HH.mm"]).format("hh:mm");
};

export const GMT_to_normal_time2 = (time) => {
  return moment(time, ["HH.mm"]).format("HH:mm");
};

//ISO date to normalDate

export const ISO_to_normal_date = (date) => {
  const normalDate = moment(date).utc().format("DD-MM-YYYY");
  return normalDate;
};

// Render Schedule dates:
export const RenderScheduleDates = (startDate, duration) => {
  let dates = [];
  let DURATION;
  let i = 52;
  let today = new Date();
  switch (duration) {
    case "week":
      DURATION = 7;
      i = 52;
      break;
    case "two_week":
      DURATION = 14;
      i = 26;
      break;
    case "month":
      DURATION = 29;
      i = 12;
      break;
    default:
      DURATION = 7;
  }

  const stopDate = new Date(startDate);
  stopDate.setDate(stopDate.getDate() + DURATION);

  var CURRENT_DATE = moment(startDate);

  while (i--) {
    if (moment(today).toISOString() < moment(CURRENT_DATE).toISOString()) {
      dates.push(moment(CURRENT_DATE).toISOString());
    }
    CURRENT_DATE = moment(CURRENT_DATE).add(DURATION, "days");
  }

  return dates;
};

//Render next date :
export const NextDate = (allDates) => {
  let today = new Date();
  let nextDate;
  let normalToday = moment(today).toISOString();

  // console.log(allDates, "all");

  for (let i = 0; i < allDates?.length; i++) {
    if (moment(allDates[i]).toISOString() > moment(normalToday).toISOString()) {
      nextDate = allDates[i];

      break;
    }
  }

  return ISO_to_normal_date(nextDate);
};

export const NextDateCheckout = (allDates) => {
  let today = new Date();
  let nextDate;
  let normalToday = moment(today).toISOString();

  for (let i = 0; i < allDates?.length; i++) {
    if (
      moment(allDates[i]).format("DD-MM-YYYY") >
      moment(normalToday).format("DD-MM-YYYY")
    ) {
      nextDate = allDates[i];
      break;
    }
  }

  return nextDate;
};

//put schedule dates in useState
export const scheduledDateState = (scheduledDates) => {
  let state = [];
  scheduledDates?.map((item) => {
    const obj = {
      id: item,
      value: item,
      isChecked: false,
    };

    state.push(obj);
  });
  return state;
};

//Filter meetings by weekly,monthly:

export const filterMeetings = (filter, list) => {
  console.log(filter, "filter");
  console.log(list, "list");
  let meetings = [];
  let date = new Date();

  const getWeekNum = (item_date) => {
    return moment(item_date, "DD-MM-YYYY").week();
  };

  const getMonthNum = (item_date) => {
    return moment(item_date, "DD-MM-YYYY").month();
  };

  switch (filter) {
    case "week":
      console.log("week");
      list.forEach((item) => {
        const scheduledDates = RenderScheduleDates(
          item?.dateOfStart,
          item?.meeting_duration
        );

        let nextMeeting =
          item?.schedule_dates?.length > 0
            ? NextDate(item?.schedule_dates)
            : NextDate(scheduledDates);

        if (moment(date).week() === getWeekNum(nextMeeting)) {
          meetings.push(item);
        }
      });

      break;

    case "month":
      list.forEach((item) => {
        const scheduledDates = RenderScheduleDates(
          item?.dateOfStart,
          item?.meeting_duration
        );

        let nextMeeting =
          item?.schedule_dates?.length > 0
            ? NextDate(item?.schedule_dates)
            : NextDate(scheduledDates);

        if (moment(date).month() === getMonthNum(nextMeeting)) {
          meetings.push(item);
        }
      });
      break;
    //Default is all meetings:
    default:
      meetings = list;
  }
  console.log(meetings, "meetings");
  return meetings;
};

export const filterMeetingsThisWeek = (filter, list) => {
  let meetings = [];
  let date = new Date();

  const getWeekNum = (item_date) => {
    return moment(item_date, "DD-MM-YYYY").week();
  };

  switch (filter) {
    case "week":
      list.forEach((item) => {
        const scheduledDates = RenderScheduleDates(
          item?.dateOfStart,
          item?.meeting_duration
        );
        let nextMeeting = NextDate(scheduledDates);
        if (moment(date).week() === getWeekNum(nextMeeting)) {
          let temp = {
            ...item,
            nextMeeting: moment(nextMeeting, "DD-MM-YYYY").format("DD.M.YYYY"),
          };
          meetings.push(temp);
        }
      });
      break;
    //Default is all meetings:
    default:
      meetings = list;
  }

  return meetings;
};

//Format date to format1 to format2:
export const formatDate = (date, format1, format2) => {
  return moment(date, format1).format(format2);
};

//Search meetings by name:
export const searchMeetingByName = (input, list) => {
  list?.length > 0 && list?.forEach((item) => {});
};

//Search products by name,category,status:
export const searchProducts = (filterType, list, inputData) => {
  let data = [];
  switch (filterType) {
    case "name":
      data = list?.filter((item) =>
        item?.name?.toLowerCase()?.includes(inputData?.toLowerCase())
      );

      break;
    case "category":
      data = list?.filter((item) =>
        item?.product_category?.title
          ?.toLowerCase()
          ?.includes(inputData?.toLowerCase())
      );
      break;
    case "status":
      data = list?.filter((item) =>
        item?.status?.toLowerCase()?.includes(inputData?.toLowerCase())
      );
      break;
    default:
      data = list;
  }

  return data;
};

//get end date:
export const getEndDate = (duration, dateOfStart) => {
  let date = new Date(dateOfStart);

  let diff;
  switch (duration) {
    case "week":
      diff = 7;
      break;

    case "two_week":
      diff = 14;
      break;

    case "month":
      diff = 29;
      break;

    default:
      diff = 7;
      break;
  }
  date.setDate(date.getDate() + diff);

  return date;
};

//Check item already added or not
export const CheckAlreadyAdded = (itemID, cartItem, meetingId) => {
  let x;

  if (cartItem?.length > 0) {
    cartItem?.forEach((_item) => {
      if (_item?.meeting_id === meetingId && _item?.product_id === itemID) {
        x = true;
      }
    });
  } else {
    x = false;
  }
  return x;
};
export const CheckAlreadyAddedProdCart = (itemID, cartItem) => {
  let x;
  if (cartItem?.length > 0) {
    cartItem?.forEach((_item) => {
      if (_item?.product_id === itemID) {
        x = true;
      }
    });
  } else {
    x = false;
  }
  return x;
};
//Regex
export function validEmail(elementValue) {
  // var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  var emailPattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return emailPattern.test(elementValue);
}

export function validPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
export function checkAmnt(str) {
  var re = /^[0-9]*$/;
  return re.test(str);
}

export function checkName(str) {
  var re = /^[a-zA-Z ]+$/;
  console.log(re.test(str));
  return re.test(str);
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...(READ MORE)";
  } else {
    return str;
  }
}

export function truncateString2(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

//get incoming meetings
export const getIncomingMeetings = ({ meetingList }) => {
  const temp = [];
  let today = new Date();
  let nextDate;
  let normalToday = moment(today).toISOString();

  meetingList?.map((item) => {
    const scheduledDates = RenderScheduleDates(
      item?.dateOfStart,
      item?.meeting_duration
    );
    for (let i = 0; i < scheduledDates?.length; i++) {
      if (
        moment(scheduledDates[i]).format("DD-MM-YYYY") >
        moment(normalToday).format("DD-MM-YYYY")
      ) {
        nextDate = scheduledDates[i];
        temp.push(item);
        break;
      }
    }
  });

  return temp;
};

export const calculateTotal = (cart) => {
  let total = 0;
  let tobePaid = 0;
  let paidatPlace = 0;
  let subtotal = 0;

  cart.map((item) => {
    total +=
      parseFloat(item?.productDetails?.pricewithvat) * parseInt(item?.item_qty);
    if (item?.productDetails?.priceAtPlace === true) {
      paidatPlace +=
        parseFloat(item?.productDetails?.pricewithvat) *
        parseInt(item?.item_qty);
    } else {
      tobePaid +=
        parseFloat(item?.productDetails?.pricewithvat) *
        parseInt(item?.item_qty);

      subtotal +=
        parseFloat(item?.productDetails?.price) * parseInt(item?.item_qty);
    }
  });

  return {
    total: parseFloat(total).toFixed(2),
    tobePaid: parseFloat(tobePaid).toFixed(2),
    paidatPlace: parseFloat(paidatPlace).toFixed(2),
    subtotal,
  };
};

export const getCategoryName = (categoryArray, id) => {
  let x = "";

  if (categoryArray?.length > 0) {
    categoryArray?.forEach((item) => {
      if (item?._id === id) {
        x = item?.title;
      }
    });
  }

  return x;
};

export const formatPhoneNumber = (input) => {
  if (!input || isNaN(input)) return "";
  if (typeof input !== "string") input = input.toString();
  return input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

//
export const checkPayAtPriceExist = (cart) => {
  let x = false;

  //for loop in cart

  for (let i = 0; i < cart.length; i++) {
    if (cart[i].productDetails?.priceAtPlace) {
      x = true;
      break;
    }
  }

  return x;
};

export const checkPayNowExist = (cart) => {
  let x = false;

  //for loop in cart

  for (let i = 0; i < cart.length; i++) {
    if (!cart[i].productDetails?.priceAtPlace) {
      x = true;
      break;
    }
  }

  return x;
};

function scrollToSmoothly(pos, time) {
  var currentPos = window.pageYOffset;
  var start = null;
  if (time == null) time = 500;
  (pos = +pos), (time = +time);
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    var progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
}

export const ScrollToTop = () => {
  scrollToSmoothly(0, 500);
  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });
  // var scrollElm = document.scrollingElement;
  // scrollElm.scrollTop = 0;
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
};

export const includesNumber = (str) => {
  return /\d/.test(str);
};

export const includeUpperCase = (str) => {
  return /[A-Z]/.test(str);
};

export const checkTodayDateWith_nextDate = (nextDate) => {
  let today = new Date();
  let todayDate = moment(today).format("DD-MM-YYYY");

  if (todayDate === nextDate) {
    return true;
  } else {
    return false;
  }
};

export const getClosingDate = (nextDate) => {
  console.log(nextDate);
  // one day before next date
  let closingDate = moment(nextDate, "DD-MM-YYYY")
    .subtract(1, "days")
    .format("DD.MM.YYYY");
  return `${closingDate} 23.59`;
};
