import { category_end_point } from "./Api_End_Points"
import axiosInstance from "./AxiosInstance"



export const fetchCategory=async()=>{
    try{
        const res=await axiosInstance.post(category_end_point.allCategory);
        return res;

    }catch(error){
        console.log(error)
    }
}

