import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  invite_member_end_point,
  meetingPage_end_point,
} from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";
export const createMeeting = createAsyncThunk("createMeeting", async (data) => {
  try {
    const res = await axiosInstance.post(
      meetingPage_end_point.createMeeting,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const inviteUsersByEmail = createAsyncThunk(
  "inviteuser",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        invite_member_end_point.List_of_invite_members_by_Id,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const inviteUsersById = createAsyncThunk(
  "inviteuserbyId",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        invite_member_end_point.invite_mem,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const initialInviteList = createAsyncThunk(
  "initialInviteList_dashboard",
  async () => {
    try {
      const res = await axiosInstance.get(invite_member_end_point.allUserList);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMymeetings = createAsyncThunk(
  "meetingList_dashboard",
  async (data) => {
    try {
      const res = await axiosInstance.post(meetingPage_end_point.myList, data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getIncomingmeetings = createAsyncThunk(
  "getIncomingmeetings",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.upcomingList,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const scheduleMeetings = createAsyncThunk(
  "meetingSchedule",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.meetingSchedule,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAnotherProducerMeetingList = createAsyncThunk(
  "anotherProducersList",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.meetingList_join_meeting,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const joinMeetingReqst = createAsyncThunk(
  "joinMeetingReq",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.joinMeeting,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getParticipentInfo = createAsyncThunk(
  "participentInfo_dashboard",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.participentInfo,
        { result: data }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const acceptInvite = createAsyncThunk("acceptInvite", async (data) => {
  try {
    const res = await axiosInstance.post(meetingPage_end_point.AcceptReq, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const rejectinvite = createAsyncThunk("rejectInvite", async (data) => {
  try {
    const res = await axiosInstance.post(meetingPage_end_point.RejectReq, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const searchUserByEmail = createAsyncThunk(
  "searchUserByEmail",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.searchByEmail,
        data
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const getRequestParticipentInfo = createAsyncThunk(
  "getRequestParticipentInfo_dashboard",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        meetingPage_end_point.participentInfo,
        { result: data }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchMeetingDetails_dashboard = createAsyncThunk(
  "fetchMeetingDetails_dashboard",
  async (id) => {
    try {
      const res = await axiosInstance.post(
        `${meetingPage_end_point.meetingDetails}/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchScheduleDates = createAsyncThunk(
  "fetchScheduleDates",
  async (data) => {
    try {
      const res = await axiosInstance.get(
        `${meetingPage_end_point.fetchScheduleDates}/${data.meeting_id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  message: null,
  meetingCreated: false,
  data: null,
  initialList: null,
  initialListStatus: "idle",
  meetingListStatus: "idle",
  meetingList: null,
  othersMeetingList: null,
  joinStatus: false,
  meetingDetailsStatus: "idle",
  participentStatus: "idle",
  participentInfo: null,
  resStatus: null,
  invited: false,
  rejected: false,
  accepted: false,
  storeInviteList: null,
  scheduleMeetingsStatus: "idle",
  scheduleMeetingSucess: false,
  inviteUserStatus: "idle",
  joinMeetingStatus: "idle",
  getAnotherProducerMeetingListStatus: "idle",
  requestparticipentInfo: [],
  createMeetingStatus: "idle",
  inviteModal: false,
  othersMeetingList_pageCount: 0,
  getMymeetings_pageCount: 0,
  meeetingDetails: null,
  fetchScheduleDatesStatus: "idle",
  fetchScheduleDatesData: null,
  getIncomingmeetingsStatus: "idle",
  getIncomingmeetingsData: null,
};

const DashboardMeetingSlice = createSlice({
  name: "dashboardMeeting",
  initialState,
  reducers: {
    handleStoreInviteList: (state, { payload }) => {
      state.storeInviteList = payload;
    },
    deleteStoreInviteList: (state, { payload }) => {
      state.storeInviteList?.splice(payload, 1);
    },

    change_scheduleMeetingSucess_to_false: (state) => {
      state.scheduleMeetingSucess = false;
    },
    openInviteModal: (state) => {
      state.inviteModal = true;
    },
    closeInviteModal: (state) => {
      state.inviteModal = false;
    },
    setscheduleMeetingSucess:(state,{payload })=>{
      state.scheduleMeetingSucess = payload
    },
    setjoinStatusMeeting:(state,{payload })=>{
      state.joinStatus = payload
    },
    reset_meetingCreated:(state,{payload})=>{
      state.meetingCreated=payload;
    }
  },
  extraReducers: (builders) => {
    builders
      //fetch getIncomingmeetings
      .addCase(getIncomingmeetings, (state, action) => {
        state.getIncomingmeetingsStatus = "loading";
      })
      .addCase(getIncomingmeetings.fulfilled, (state, { payload }) => {
        state.getIncomingmeetingsStatus = "idle";
        if (payload?.status === 200) {
          state.getIncomingmeetingsData = payload?.data;
        } else {
          state.getIncomingmeetingsData = [];
        }
      })
      .addCase(getIncomingmeetings.rejected, (state, action) => {
        state.getIncomingmeetingsStatus = "idle";
      })
      //Fetch meetings
      .addCase(fetchMeetingDetails_dashboard.pending, (state) => {
        state.meetingDetailsStatus = "loading";
        state.msg = null;
        state.meeetingDetails = null;
      })
      .addCase(
        fetchMeetingDetails_dashboard.fulfilled,
        (state, { payload }) => {
          state.msg = payload?.message;

          state.meetingDetailsStatus = "idle";
          if (payload?.status === 200) {
            state.meeetingDetails = payload?.data;
          }
        }
      )
      .addCase(fetchMeetingDetails_dashboard.rejected, (state) => {
        state.meetingDetailsStatus = "idle";
        state.msg = "something Went wrong";
      })
      //producer create meeting
      .addCase(createMeeting.pending, (state) => {
        state.createMeetingStatus = "loading";
        state.meetingCreated = false;
      })
      .addCase(createMeeting.fulfilled, (state, { payload }) => {
        state.createMeetingStatus = "idle";

        if (payload?.status === 200) {
          state.meetingCreated = true;
          state.data = payload?.data;
        }
      })
      .addCase(createMeeting.rejected, (state, { payload }) => {
        state.createMeetingStatus = "idle";
      })
      //initial invite list
      .addCase(initialInviteList.pending, (state) => {
        state.initialListStatus = "loading";
        state.initialList = null;
      })
      .addCase(initialInviteList.fulfilled, (state, { payload }) => {
        state.initialListStatus = "idle";

        if (payload?.status === 200) {
          state.initialList = payload?.data?.data;
        } else {
          state.initialList = [];
        }
      })
      .addCase(initialInviteList.rejected, (state, { payload }) => {
        state.initialListStatus = "idle";
      })
      //producer meeting list
      .addCase(getMymeetings.pending, (state) => {
        state.meetingListStatus = "loading";
        state.invited = false;
        state.accepted = false;
        state.rejected = false;
        state.joinStatus=false;
      })
      .addCase(getMymeetings.fulfilled, (state, { payload }) => {
        state.meetingListStatus = "idle";

        if (payload?.status === 200) {
          state.meetingList = payload?.data;
          state.getMymeetings_pageCount = payload?.pages;
        }
      })
      .addCase(getMymeetings.rejected, (state, { payload }) => {
        state.meetingListStatus = "idle";
        state.message = "something went wrong";
      })
     
      .addCase(scheduleMeetings.pending, (state) => {
        state.scheduleMeetingsStatus = "loading";
      })
      .addCase(scheduleMeetings.fulfilled, (state, { payload }) => {
        state.scheduleMeetingsStatus = "idle";
        if (payload?.status === 200) {
          state.scheduleMeetingSucess = true;
          // toast(payload?.message, { type: "success" });
        } else {
           toast(payload?.message, { type: "error" });
        }
      })
      .addCase(scheduleMeetings.rejected, (state, { payload }) => {
        state.scheduleMeetingsStatus = "idle";
        state.message = "something went wrong";
      })
      // get schedule dates value:
      .addCase(fetchScheduleDates.pending, (state) => {
        state.fetchScheduleDatesStatus = "loading";
        state.fetchScheduleDatesData = null;
      })
      .addCase(fetchScheduleDates.fulfilled, (state, { payload }) => {
        state.fetchScheduleDatesStatus = "idle";
        if (payload?.status === 200) {
          state.fetchScheduleDatesData = payload.data[0];
        } else {
          state.fetchScheduleDatesData = null;
        }
      })
      .addCase(fetchScheduleDates.rejected, (state, { payload }) => {
        state.fetchScheduleDatesStatus = "idle";
        state.message = "something went wrong";
      })

      //get producers meetings
      .addCase(getAnotherProducerMeetingList.pending, (state) => {
        state.getAnotherProducerMeetingListStatus = "loading";
        state.joinStatus = false;
      })
      .addCase(
        getAnotherProducerMeetingList.fulfilled,
        (state, { payload }) => {
          state.getAnotherProducerMeetingListStatus = "idle";

          if (payload?.status === 200) {
            state.othersMeetingList = payload?.data;
            state.othersMeetingList_pageCount = payload?.pages;
          } else {
            state.othersMeetingList = [];
          }
        }
      )
      .addCase(getAnotherProducerMeetingList.rejected, (state, { payload }) => {
        state.getAnotherProducerMeetingListStatus = "idle";
        state.message = "something went wrong";
      })
      //join meeting req
      .addCase(joinMeetingReqst.pending, (state) => {
        state.joinMeetingStatus = "loading";
        state.joinStatus = false;
        state.resStatus = null;
        state.message = null;
      })
      .addCase(joinMeetingReqst.fulfilled, (state, { payload }) => {
        state.joinMeetingStatus = "idle";
        state.message = payload?.message;
        state.resStatus = payload?.status;

        if (payload?.status === 200) {
          state.joinStatus = true;
          state.othersMeetingList?.forEach((element, index) => {
            if (element?._id === payload?.data?._id) {
              state.othersMeetingList[index] = payload?.data;
            }
          });
          // toast("Join request sent", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(joinMeetingReqst.rejected, (state, { payload }) => {
        state.joinMeetingStatus = "idle";
        state.message = "something went wrong";
      })
      //participent
      .addCase(getParticipentInfo.pending, (state) => {
        state.participentStatus = "loading";
        state.joinStatus = false;
      })
      .addCase(getParticipentInfo.fulfilled, (state, { payload }) => {
        state.participentStatus = "idle";

        if (payload?.status === 200) {
          state.participentInfo = payload?.data;
        }
      })
      .addCase(getParticipentInfo.rejected, (state, { payload }) => {
        state.participentStatus = "idle";
        state.message = "something went wrong";
      })

      //reject invite
      .addCase(rejectinvite.pending, (state) => {
        state.status = "loading";
        state.resStatus = null;
        state.message = null;
        state.rejected = false;
      })
      .addCase(rejectinvite.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        state.resStatus = payload?.status;

        if (payload?.status === 200) {
          state.rejected = true;

          state.meeetingDetails = payload?.data;
          // state.meetingList?.forEach((element, index) => {
          //   if (element?._id === payload?.data?._id) {
          //     state.meetingList[index] = payload?.data;
          //   }
          // });
        }
      })
      .addCase(rejectinvite.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
      //accept invite
      .addCase(acceptInvite.pending, (state) => {
        state.status = "loading";
        state.resStatus = null;
        state.message = null;
        state.accepted = false;
      })
      .addCase(acceptInvite.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        state.resStatus = payload?.status;
        if (payload?.status === 200) {
          // state.meetingList?.forEach((element, index) => {
          //   if (element?._id === payload?.data?._id) {
          //     state.meetingList[index] = payload?.data;
          //   }
          // });

          state.meeetingDetails = payload?.data;
          state.accepted = true;
        }
      })
      .addCase(acceptInvite.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
      //invite members
      .addCase(inviteUsersById.pending, (state) => {
        state.inviteUserStatus = "loading";
        state.resStatus = null;
        state.message = null;
        state.invited = false;
        state.meetingCreated = false;
      })
      .addCase(inviteUsersById.fulfilled, (state, { payload }) => {
        state.inviteUserStatus = "idle";
        state.message = payload?.message;

        if (payload?.status === 200) {
          state.invited = true;
          state.storeInviteList = null;
          // state.meetingList?.forEach((element, index) => {
          //   if (element?._id === payload?.data?._id) {
          //     console.log(state.meetingList,index,payload?.data)
          //     state.meetingList[index] = payload?.data;
          //   }
          // });
          // if (state.meeetingDetails?._id === payload?.data?._id) {
          //   state.meetingList = payload?.data;
          // }
          toast("Members are invited", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(inviteUsersById.rejected, (state, { payload }) => {
        state.inviteUserStatus = "idle";
        state.message = "something went wrong";
      })
      //search user by email
      .addCase(searchUserByEmail.pending, (state) => {
        state.initialListStatus = "loading";
        state.initialList = null;
      })
      .addCase(searchUserByEmail.fulfilled, (state, { payload }) => {
        state.initialListStatus = "idle";

        if (payload?.status === 200) {
          state.initialList = payload?.data[0]?.data;
        } else {
          state.initialList = [];
        }
      })
      .addCase(searchUserByEmail.rejected, (state, { payload }) => {
        state.initialListStatus = "idle";
      })
      .addCase(getRequestParticipentInfo.pending, (state) => {
        state.status = "loading";
        state.joinStatus = false;
        state.requestparticipentInfo = null;
      })
      .addCase(getRequestParticipentInfo.fulfilled, (state, { payload }) => {
        state.status = "idle";

        if (payload?.status === 200) {
          state.requestparticipentInfo = payload?.data;
        }
      })
      .addCase(getRequestParticipentInfo.rejected, (state, { payload }) => {
        state.status = "idle";
        state.message = "something went wrong";
      });
  },
});
export const {
  handleStoreInviteList,
  change_scheduleMeetingSucess_to_false,
  deleteStoreInviteList,
  openInviteModal,
  closeInviteModal,
  setscheduleMeetingSucess,
  setjoinStatusMeeting,
  reset_meetingCreated
} = DashboardMeetingSlice.actions;
export default DashboardMeetingSlice.reducer;
