import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cms_end_points } from "../../Api/Api_End_Points";
import privateAxiosInstance from "../../Api/AxiosInstance";

const initialState = {
  message: null,
  test_data: null,
  test_status: "idle",
};

export const FetchTestimonials = createAsyncThunk("/testimonials", async () => {
  try {
    const res = await privateAxiosInstance.get(cms_end_points.testimonials);
    return res.data;
  } catch (error) {
    // console.log("error", error);
  }
});

export const TestomonialSlice = createSlice({
  name: "testimonialSlice",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(FetchTestimonials.pending, (state) => {
        state.test_status = "loading";
        state.test_data=null;
        // console.log("pending");
      })
      .addCase(FetchTestimonials.fulfilled, (state, { payload }) => {
        state.test_status = "idle";
        if (payload?.status === 200) {
          state.test_data = payload?.data;
        } else {
          state.test_data = [];
        }

        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchTestimonials.rejected, (state) => {
        state.test_status = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      });
  },
});

export default TestomonialSlice.reducer;
