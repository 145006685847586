import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "@popperjs/core/dist/cjs/popper.js";
import "bootstrap/dist/js/bootstrap.min.js";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from "react-redux";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import RouterModule from "./routerModule";
import Store from "./redux/Store/Store";
import { toast } from 'react-toastify'
toast.configure();

ReactDOM.render(

  <React.StrictMode>
    <Provider store={Store}>
      <RouterModule />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
