import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
toast.configure();
const PrivateRoutes = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const { userDetails } = useSelector((s) => s.signIn);
  const history = useHistory();

  useEffect(() => {
    if (userDetails !== null) {
      if (userDetails?.isSignupCompleted === false) {
       
        sessionStorage.setItem("email", userDetails?.email);
        history.push("/user-authentication/verify");
      }
    }
  }, [location, userDetails]);
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to="/user-authentication" />
          </>
        )
      }
    />
  );
};

export const AfterSignUpPageverifyOtp = ({ component: Component, ...rest }) => {
  const signUp_email = sessionStorage.getItem("email");

  return (
    <Route
      {...rest}
      render={(props) => {
        signUp_email !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/user-authentication" />
        );
      }}
    />
  );
};

// export const CreateSubscription = ({ component: Component, ...rest }) => {
//   const signUp = useSelector((s) => s.signUp);
//   const dispatch=useDispatch();

//   useEffect(()=>{
//     dispatch()
//   },[])

//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         signUp?.verified || signUp?.signUp_fb ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/" />
//         );
//       }}
//     />
//   );
// };

export default PrivateRoutes;
