import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { order_end_point } from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";

export const getCustomerOrders = createAsyncThunk(
  "getCustomerOrders",
  async (data) => {
    try {
      const res = await axiosInstance.post(order_end_point.getUserOrder,data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMyOrders = createAsyncThunk(
  "getMyOrders",
  async (data) => {
    try {
      const res = await axiosInstance.post(order_end_point.myOrder,data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCustomerOrdersDetails=createAsyncThunk(
  "getCustomerOrdersDetails",
  async (data) => {
    try {
      const res = await axiosInstance.post(order_end_point.getOrderDetails,data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  detailsStatus:'idle',
  customerOrders: null,
  customerOrderDetails:null,
  customerOrderDetailsStatus:'idle',
  myorderStatus:'idle',
  myOrdersdata:null,
};

const customerOrderSlice = createSlice({
  name: "customerOrder",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getCustomerOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomerOrders.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          state.customerOrders = payload?.data;
        } else {
          state.customerOrders = [];
        }
      })
      .addCase(getCustomerOrders.rejected, (state) => {
        state.status = "idle";
      })

      .addCase(getMyOrders.pending, (state) => {
        state.myorderStatus = "loading";
        state.myOrdersdata = null;
      })
      .addCase(getMyOrders.fulfilled, (state, { payload }) => {
        state.myorderStatus = "idle";
        if (payload?.status === 200) {
          state.myOrdersdata = payload?.data;
        } else {
          state.myOrdersdata = [];
        }
      })
      .addCase(getMyOrders.rejected, (state) => {
        state.myorderStatus = "idle";
      })


      .addCase(getCustomerOrdersDetails.pending, (state) => {
        state.detailsStatus = "loading";
      })
      .addCase(getCustomerOrdersDetails.fulfilled, (state, { payload }) => {
        state.detailsStatus = "idle";
        if (payload?.status === 200) {
          console.log(payload?.data)
          state.customerOrderDetails = payload?.data;
        } else {
          state.customerOrderDetails = [];
        }
      })
      .addCase(getCustomerOrdersDetails.rejected, (state) => {
        state.detailsStatus = "idle";
      })

  },
});

export default customerOrderSlice.reducer;
