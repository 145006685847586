import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./routerModule.scss";
import { useDispatch } from "react-redux";
import {
  getUserDetails,
  getUserDetailsFromSession,
  isLogeedIn,
  verifyToken,
} from "./redux/AuthSlice/SignInSlice/SignInSlice";
import PrivateRoutes from "./PrivateRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import "react-lazy-load-image-component/src/effects/blur.css";

import { PageLoading } from "./Share_Component/Loading";
const App = lazy(() => import("./App"));
const ProducerDashboardModule = lazy(() =>
  import("./Producer_Dashboard_Module/ProducerDashboardModule")
);

const UserAuthenticationModule = lazy(() =>
  import("./User_Authentication_Module/UserAuthenticationModule")
);


// const useBackButton = () => {
//   const navType = useNavigationType();
//   return navType === navType.Pop;
// };

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // const isPop = useBackButton();
  const scrollToTop = () => (document.body.scrollTop = 0);
  useEffect(() => {

    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, [pathname, window.href]);

  useEffect(() => {
    window.addEventListener("beforeunload", scrollToTop);
    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  return null;
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "fi",
    interpolation: {
      escapeValue: false,
    },
    defaultLocale: "fi",
    detection: {
      order: [
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
        "querystring",
      ],
      caches: ["cookie"],
    },
    backend: {
      // loadPath: "/languages/{{lng}}.json",

      // loadPath: "http://localhost:2000/api/{{lng}}",
      loadPath: process.env.REACT_APP_API_URL + "api/custom_translations/{{lng}}",
      crossDomain: true,
    },
  });

export default function RouterModule() {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem("token");

    // token !== null && dispatch(getUserDetails());

    if (token !== null) {
      dispatch(verifyToken());
      dispatch(getUserDetailsFromSession());
    }

    dispatch(isLogeedIn());
    // dispatch(getTrnaslation());
  }, []);


  // useEffect(()=>{
  //   console.log(location)
  // },[location])

  return (
    <>
      {/* <PageLoading /> */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        closeButton={true}
        toastStyle={{
          backgroundColor: "white",
          color: "black",
          zIndex: 999999,
        }}
      />
      <Suspense
        fallback={
          <>
            <PageLoading />
          </>
        }
       
      >
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/user-authentication">
              <UserAuthenticationModule />
            </Route>

            <PrivateRoutes
              restricted={true}
              path="/dashboard"
              component={ProducerDashboardModule}
            />

            <Route path="/">
              <App />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </>
  );
}
