import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { cart_end_point } from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";
import { calculateTotal } from "../../utils/utils";

export const addToCart = createAsyncThunk("AddToCart", async (data) => {
  try {
    const res = await axiosInstance.post(cart_end_point.add_to_cart, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const addToProducerCart = createAsyncThunk(
  "addToProducerCart",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        cart_end_point.add_to_cart_prodCart,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const onLogin_addToCart = createAsyncThunk(
  "Local cart to server",
  async (data) => {
    try {
      const localCart = JSON.parse(localStorage.getItem("localCart"));
      if (localCart?.length > 0) {
        localCart?.map(async (item) => {
          const res = await axiosInstance.post(cart_end_point.add_to_cart, {
            product_id: item?._id,
            item_qty: item?.item_qty,
          });
          return res.data;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMyCartItem = createAsyncThunk("mycartItem", async () => {
  try {
    const res = await axiosInstance.get(cart_end_point.getMyCart);

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getProducerCartItem = createAsyncThunk(
  "getProducerCartItem",
  async () => {
    try {
      const res = await axiosInstance.get(cart_end_point.getMyCart_prodCart);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateQuantity = createAsyncThunk(
  "updateQuantity",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        `${cart_end_point.UpdateCartItem}/${data.id}`,
        { item_qty: data.qty }
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateQuantityProducerCart = createAsyncThunk(
  "updateQuantityProducerCart",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        `${cart_end_point.UpdateCartItem_prodCart}/${data.id}`,
        { item_qty: data.qty }
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeFromCart = createAsyncThunk("removeFromCart", async (id) => {
  try {
    const res = await axiosInstance.get(
      `${cart_end_point.RemoveFromCart}/${id}`
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const removeFromProducerCart = createAsyncThunk(
  "removeFromProducerCart",
  async (id) => {
    try {
      const res = await axiosInstance.get(
        `${cart_end_point.RemoveFrom_prodCart}/${id}`
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCartTotal = createAsyncThunk("cartTotal", async () => {
  try {
    const res = await axiosInstance.get();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getProducerCartTotal = createAsyncThunk(
  "getProducerCartTotal",
  async () => {
    try {
      const res = await axiosInstance.get();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  Add_to_cart_msg: null,
  addToCartStatus: "idle",
  cartItems: null,
  inCartMsg: null,
  deleted: false,
  deletedStatus: "idle",
  updated: false,
  addedToCart: false,
  cartTotal: 0,
  cartSubtotal:0,
  localToServer: false,
  status_prodCart: "idle",
  Add_to_cart_msg_prodCart: null,
  addToCartStatus_prodCart: "idle",
  cartItems_prodCart: null,
  inCartMsg_prodCart: null,
  deleted_prodCart: false,
  deletedStatus_prodCart: "idle",
  updated_prodCart: false,
  addedToCart_prodCart: false,
  cartTotal_prodCart: 0,
  localToServer_prodCart: false,
  updateQuantityStatus: "idle",
  updateQuantityStatus_prodcart: "idle",
  cartTotalTobePaid: 0,
  cartTotalTobePaid_atplace: 0,
  cartHash: {},
  
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    AddToCartLocal: (state, { payload }) => {
      const localCart = localStorage.getItem("localCart")
        ? JSON.parse(localStorage.getItem("localCart"))
        : [];

      localCart.push(payload);

      localStorage.setItem("localCart", JSON.stringify(localCart));
      state.addedToCart = true;
      state.cartItems = localCart;
    },
    fetchCartLocal: (state) => {
      const localCart = localStorage.getItem("localCart")
        ? JSON.parse(localStorage.getItem("localCart"))
        : [];

      if (localCart?.length > 0) {
        state.cartItems = localCart;
      }
    },

    updateQuantityLocal: (state, { payload }) => {
      const localCart = localStorage.getItem("localCart")
        ? JSON.parse(localStorage.getItem("localCart"))
        : [];

      localCart[payload?.index].item_qty = payload?.item_qty;
      localStorage.setItem("localCart", JSON.stringify(localCart));
      fetchCartLocal();
    },

    deleteProductLocal: (state, { payload }) => {
      const localCart = localStorage.getItem("localCart")
        ? JSON.parse(localStorage.getItem("localCart"))
        : [];

      localCart?.splice(payload?.index, 1);
      state.deleted = true;
      state.inCartMsg = "Deleted";
      localStorage.setItem("localCart", JSON.stringify(localCart));
    },
    calculateCartTotal: (state) => {
      let x = calculateTotal(state.cartItems);
      
      state.cartTotal = x?.total;
      state.cartSubtotal=x?.subtotal;
      state.cartTotalTobePaid = x?.tobePaid;
      state.cartTotalTobePaid_atplace = x?.paidatPlace;
    },
    reset_addToCartStatus: (state, { payload }) => {
      state.addToCartStatus = "idle";
      state.Add_to_cart_msg = null;
    },
  },
  extraReducers: (builders) => {
    builders
      //add to cart
      .addCase(addToCart.pending, (state) => {
        state.Add_to_cart_msg = null;
        state.addToCartStatus = "loading";
        state.addedToCart = false;
      })
      .addCase(addToCart.fulfilled, (state, { payload }) => {
        state.Add_to_cart_msg = payload?.message;
        if (payload?.status === 200) {
          state.addToCartStatus = "idle";
          // state.Add_to_cart_msg = "Added to cart";
          state.addedToCart = true;
          // state.cartItems = payload?.data;
          // state.cartTotal = payload?.Total;
        } else {
          state.addToCartStatus = "error";
          // toast(payload?.message, { type: "error", toastId: "addToCartToast" });
        }
      })
      .addCase(addToCart.rejected, (state) => {
        state.addToCartStatus = "idle";
        state.Add_to_cart_msg = "Something went wrong";
      })
      // get cart item
      .addCase(getMyCartItem.pending, (state) => {
        state.inCartMsg = null;
        state.addedToCart = false;
        state.status = "loading";
        state.cartItems = null;
      })
      .addCase(getMyCartItem.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          state.cartItems = payload?.data;
          // state.cartTotal = payload?.Total;
          let x = calculateTotal(state.cartItems);
          state.cartTotal = x.total;
          state.cartSubtotal=x?.subtotal;
          state.cartTotalTobePaid = x.tobePaid;
          state.cartTotalTobePaid_atplace = x.paidatPlace;
          state.cartHash = {};

          if (state?.cartItems?.length > 0) {
            state.cartHash = {
              meeting_id: state.cartItems[0]?.meeting_id,
            };

            state.cartItems?.map((item) => {
              state.cartHash = {
                ...state.cartHash,
                [item?.product_id]: true,
              };
            });
          }
        }
      })
      .addCase(getMyCartItem.rejected, (state) => {
        state.status = "idle";
        state.inCartMsg = "Something went wrong";
      })
      // updte qnty
      .addCase(updateQuantity.pending, (state) => {
        state.updateQuantityStatus = "loading";
      })
      .addCase(updateQuantity.fulfilled, (state, { payload }) => {
        state.updateQuantityStatus = "idle";
        state.inCartMsg = payload?.message;
        state.updated = true;
        if (payload?.status === 200) {
          state.cartItems = payload?.data;
          // state.cartTotal = calculateTotal(state.cartItems);
          let x = calculateTotal(state.cartItems);
          state.cartTotal = x.total;
          state.cartSubtotal=x?.subtotal;
          state.cartTotalTobePaid = x.tobePaid;
          state.cartTotalTobePaid_atplace = x.paidatPlace;
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(updateQuantity.rejected, (state) => {
        state.updateQuantityStatus = "idle";
        state.updated = false;
      })
      // remove from cart
      .addCase(removeFromCart.pending, (state) => {
        state.deletedStatus = "loading";
        state.inCartMsg = null;
        state.deleted = false;
      })
      .addCase(removeFromCart.fulfilled, (state, { payload }) => {
        state.deletedStatus = "idle";
        if (payload?.status === 200) {
          state.deleted = true;
          // state.inCartMsg = "Deleted";
          state.cartItems = payload?.data;
          // state.cartTotal = calculateTotal(state.cartItems);
          let x = calculateTotal(state.cartItems);
          state.cartTotal = x.total;
          state.cartSubtotal=x?.subtotal;
          state.cartTotalTobePaid = x.tobePaid;
          state.cartTotalTobePaid_atplace = x.paidatPlace;

          state.cartHash = {};

          if (state?.cartItems?.length > 0) {
            state.cartHash = {
              meeting_id: state.cartItems[0]?.meeting_id,
            };

            state.cartItems?.map((item) => {
              state.cartHash = {
                ...state.cartHash,
                [item?.product_id]: true,
              };
            });
          }

          // toast("Deleted", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(removeFromCart.rejected, (state) => {
        state.deletedStatus = "idle";
        state.inCartMsg = "Something went wrong";
      })
      //after log in tranfer local cart to server
      .addCase(onLogin_addToCart.pending, (state) => {
        state.localToServer = false;
      })
      .addCase(onLogin_addToCart.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.localToServer = true;
        }
      })
      .addCase(onLogin_addToCart.rejected, (state) => {
        state.localToServer = true;
      })
      // Add to producer cart
      .addCase(addToProducerCart.pending, (state) => {
        state.Add_to_cart_msg_prodCart = null;
        state.addToCartStatus_prodCart = "loading";
        state.addedToCart_prodCart = false;
      })
      .addCase(addToProducerCart.fulfilled, (state, { payload }) => {
        state.addToCartStatus_prodCart = "idle";
        if (payload?.status === 200) {
          state.Add_to_cart_msg_prodCart = "Added to cart";
          state.addedToCart_prodCart = true;

          toast("Added to producer cart", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(addToProducerCart.rejected, (state) => {
        state.addToCartStatus_prodCart = "idle";
        state.Add_to_cart_msg_prodCart = "Something went wrong";
      })

      //get producer cart item
      .addCase(getProducerCartItem.pending, (state) => {
        state.inCartMsg_prodCart = null;
        state.addedToCart_prodCart = false;
        state.status_prodCart = "loading";
      })
      .addCase(getProducerCartItem.fulfilled, (state, { payload }) => {
        state.status_prodCart = "idle";
        if (payload?.status === 200) {
          state.cartItems_prodCart = payload?.data;
          state.cartTotal_prodCart = payload?.Total;
        }
      })
      .addCase(getProducerCartItem.rejected, (state) => {
        state.status_prodCart = "idle";
        state.inCartMsg_prodCart = "Something went wrong";
      })
      // update producer cart
      .addCase(updateQuantityProducerCart.pending, (state) => {
        state.updateQuantityStatus_prodcart = "loading";
      })
      .addCase(updateQuantityProducerCart.fulfilled, (state, { payload }) => {
        state.updateQuantityStatus_prodcart = "idle";
        state.inCartMsg_prodCart = payload?.message;
        state.updated_prodCart = true;
        if (payload?.status === 200) {
          state.cartItems_prodCart = payload?.data;
          state.cartTotal_prodCart = calculateTotal(state.cartItems_prodCart);
          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(updateQuantityProducerCart.rejected, (state) => {
        state.updateQuantityStatus_prodcart = "idle";
        state.updated_prodCart = false;
      })
      // Remove from producer cart
      .addCase(removeFromProducerCart.pending, (state) => {
        state.deletedStatus_prodCart = "loading";
        state.inCartMsg_prodCart = null;
        state.deleted_prodCart = false;
      })
      .addCase(removeFromProducerCart.fulfilled, (state, { payload }) => {
        state.deletedStatus_prodCart = "idle";
        if (payload?.status === 200) {
          state.deleted_prodCart = true;
          state.cartItems_prodCart = payload?.data;
          state.cartTotal_prodCart = calculateTotal(state.cartItems_prodCart);

          // state.inCartMsg = "Deleted";
          // toast("Deleted", { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(removeFromProducerCart.rejected, (state) => {
        state.deletedStatus_prodCart = "idle";
        state.inCartMsg_prodCart = "Something went wrong";
      });
  },
});
export const {
  AddToCartLocal,
  fetchCartLocal,
  updateQuantityLocal,
  deleteProductLocal,
  calculateCartTotal,
  reset_addToCartStatus,
} = cartSlice.actions;
export default cartSlice.reducer;
