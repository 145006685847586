import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cms_end_points } from "../../Api/Api_End_Points";
import  privateAxiosInstance  from "../../Api/AxiosInstance";



export const FetchAboutUs = createAsyncThunk("/aboutus", async () => {
  try {
    const res = await privateAxiosInstance.get(cms_end_points.about_us);
  
    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});

export const FetchFaq = createAsyncThunk("/FetchFaq", async () => {
  try {
    const res = await privateAxiosInstance.post(cms_end_points.faq);
  
    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});
export const FetchPrivacy = createAsyncThunk("/FetchPrivacy", async () => {
  try {
    const res = await privateAxiosInstance.post(cms_end_points.privacy);
  
    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});

export const FetchTerms = createAsyncThunk("/FetchTerms", async () => {
  try {
    const res = await privateAxiosInstance.post(cms_end_points.terms);
  
    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});

const initialState = {
  message: null,
  data: null,
  status: "idle",
  faqStatus:'idle',faqData:null,
  privacyStatus:'idle',privacyData:null,
  termsStatus:'idle',termsData:null,
};

export const AboutUsSlice = createSlice({
  name: "aboutUsSlice",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(FetchAboutUs.pending, (state) => {
        state.status = "loading";
        state.data=null
        // console.log("pending");
      })
      .addCase(FetchAboutUs.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.data = payload?.data;
        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchAboutUs.rejected, (state) => {
        state.status = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      })

      .addCase(FetchFaq.pending, (state) => {
        state.faqStatus = "loading";
        // console.log("pending");
      })
      .addCase(FetchFaq.fulfilled, (state, { payload }) => {
        state.faqStatus = "idle";
        if(payload?.status===200){
          state.faqData = payload?.data;
        }else{
          state.faqData = []
        }
        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchFaq.rejected, (state) => {
        state.faqStatus = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      })
      //privacy
      .addCase(FetchPrivacy.pending, (state) => {
        state.privacyStatus = "loading";
        // console.log("pending");
      })
      .addCase(FetchPrivacy.fulfilled, (state, { payload }) => {
        state.privacyStatus = "idle";
        if(payload?.status===200){
          state.privacyData = payload?.data;
        }else{
          state.privacyData = []
        }
        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchPrivacy.rejected, (state) => {
        state.privacyStatus = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      })

      //terms
      .addCase(FetchTerms.pending, (state) => {
        state.termsStatus = "loading";
        // console.log("pending");
      })
      .addCase(FetchTerms.fulfilled, (state, { payload }) => {
        state.termsStatus = "idle";
        if(payload?.status===200){
          state.termsData = payload?.data;
        }else{
          state.termsData = []
        }
        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchTerms.rejected, (state) => {
        state.termsStatus = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      })


  },
});

export default AboutUsSlice.reducer;
