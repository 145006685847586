import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  producers_end_point,
  user_end_pont,
} from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";

export const updateProfile = createAsyncThunk("updateProfile", async (data) => {
  try {
    const res = await axiosInstance.post(user_end_pont.updateProfile, data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const passwordUpdateUser = createAsyncThunk(
  "passwordUpdateUser",
  async (user) => {
    try {
      let res;

      res = await axiosInstance.post(producers_end_point.passwordUpdate, user);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  message: null,
  updateProfileStatus: "idle",
  profileUpdated: false,
  passwordUpdateStatus: "idle",
  passwordUpdated: false,
};

const userSetting = createSlice({
  name: "userSetting",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(updateProfile.pending, (state) => {
        state.updateProfileStatus = "loading";
        state.profileUpdated = false;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.updateProfileStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          toast(payload?.message, { type: "success" });
          state.profileUpdated = true;
        } else {
          toast(payload?.message, { type: "error" });
          state.profileUpdated = false;
        }
      })
      .addCase(updateProfile.rejected, (state) => {
        state.updateProfileStatus = "idle";
        state.message = "something went wrong";
      })

      .addCase(passwordUpdateUser.pending, (state) => {
        state.passwordUpdateStatus = "loading";
        state.message = null;
      })
      .addCase(passwordUpdateUser.fulfilled, (state, { payload }) => {
        state.passwordUpdateStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.passwordUpdated = true;

          toast(payload?.message, { type: "success" });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(passwordUpdateUser.rejected, (state, action) => {
        state.passwordUpdateStatus = "idle";
        state.message = "Something went wrong";
      });
  },
});

export default userSetting.reducer;
