import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { order_end_point } from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";

export const getTransactionFees = createAsyncThunk(
  "getTransactionFees",
  async () => {
    try {
      const res = await axiosInstance.get(order_end_point.getTransactionFees);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createOrder=createAsyncThunk("createOrder",async(data)=>{
  try{
    const res=await axiosInstance.post(order_end_point.orderCreate,{amount:data});
    return res.data;
  }catch(error){
    console.log(error)
  }
})



const initialState = {
  status: "idle",
  transactionFees: null,
  message: null,
  orderPlaced:null
};

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getTransactionFees.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(getTransactionFees.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.transactionFees = payload?.data;
        }
        state.message = payload?.message;
      })
      .addCase(getTransactionFees.rejected, (state) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
      //order create
      .addCase(createOrder.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(createOrder.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.orderPlaced = payload?.data;
        }
        state.message = payload?.message;
      })
      .addCase(createOrder.rejected, (state) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
  },
});
export default orderSlice.reducer;
