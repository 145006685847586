import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import produce from "@reduxjs/toolkit/node_modules/immer";
import axiosInstance from "../../../Api/AxiosInstance";

export const getNotifications = createAsyncThunk(
  "getNotification",
  async () => {
    try {
      const x = "notification/getCreatorId";
      const res = await axiosInstance.get(x);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const readNotification = createAsyncThunk(
  "readNotification",
  async (data) => {
    try {
      const x = "/notification/mark-read";
      const res = await axiosInstance.post(x, data);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
);

const initialState = {
  status: "idle",
  Notifications: null,
  totalUnreadNotifications: 0,
  readNotificationStatus: "idle",
  readNotificationData: null,
  readNotification_sucess: true,
};

const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,
  extraReducers: (builders) => {
    builders
      .addCase(getNotifications.pending, (state) => {
        state.status = "loading";
        state.totalUnreadNotifications = 0;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.status = "idle";
        // console.log("notification data",payload?.data)

        if (payload?.status === 200) {
          state.Notifications = payload?.data;
          state.totalUnreadNotifications = payload?.count;
        } else {
          state.Notifications = [];
        }
      })
      .addCase(getNotifications.rejected, (state) => {
        state.status = "idle";
      })

      //mark as read
      .addCase(readNotification.pending, (state) => {
        state.readNotificationStatus = "loading";
        state.readNotification_sucess = false;
      })
      .addCase(readNotification.fulfilled, (state, { payload }) => {
        state.readNotificationStatus = "idle";
        // console.log("notification data",payload?.data)

        if (payload?.status === 200) {
          state.readNotificationData = payload?.data;
          state.readNotification_sucess = true;
          state.totalUnreadNotifications = payload?.count;

          const productList = current(state.Notifications);
          produce(productList, (draftState) => {
            let index = productList.findIndex(
              (item) => item?._id === payload?.data[0]?._id
            );

            draftState[index] = payload?.data[0];
            state.Notifications = current(draftState);
          });
        } else {
          state.Notifications = [];
        }
      })
      .addCase(readNotification.rejected, (state) => {
        state.readNotificationStatus = "idle";
      });
  },
});

export default NotificationSlice.reducer;
