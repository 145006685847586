import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cms_end_points } from "../../Api/Api_End_Points";
import  privateAxiosInstance  from "../../Api/AxiosInstance";

const initialState = {
  message: null,
  data: [],
  status: "idle",
};

export const FetchCity = createAsyncThunk("/city", async () => {
  try {
    const res = await privateAxiosInstance.get(cms_end_points.city);
    return res.data;
  } catch (error) {
    // console.log("error", error);
  }
});

export const citySlice = createSlice({
  name: "citySlice",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(FetchCity.pending, (state) => {
        state.status = "loading";
        // console.log("pending");
      })
      .addCase(FetchCity.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.data =payload?.data ;
        state.message = payload?.message;
        // console.log("full");
      })
      .addCase(FetchCity.rejected, (state) => {
        state.status = "idle";
        state.message = "Something went wrong";
        // console.log("rejected");
      });
  },
});

export default citySlice.reducer;
