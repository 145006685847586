import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { contact_end_point } from "../../Api/Api_End_Points";
import  privateAxiosInstance  from "../../Api/AxiosInstance";
export const SubmitContactUsForm = createAsyncThunk("contactUsForm", async (info) => {
  try {
      const res=await privateAxiosInstance.post(contact_end_point.contact_us,info);

      return res.data;
  } catch (error) {
    console.log(error);
  }
});
const initialState = {
  status: "idle",
  message: null,
  contactStatus:null
};

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(SubmitContactUsForm.pending, (state) => {
        state.status = "loading";
        state.contactStatus=null;
      })
      .addCase(SubmitContactUsForm.fulfilled, (state, { payload }) => {
        state.message = payload?.message;
        state.status = "idle";
        if(payload?.status===200){
          state.contactStatus=200
          toast(payload?.message,{type:'success'})
        }else{
          toast(payload?.message,{type:'error'})
        }
      })
      .addCase(SubmitContactUsForm.rejected, (state) => {
        state.status = "idle";
        state.message = "Something Went wrong";
      });
  },
});

export default contactUsSlice.reducer;
