export const baseURL = process.env.REACT_APP_API_URL + "api";
//export const baseURL ="https://8ed6-2405-201-d00b-1a1-c074-941e-5fcb-c463.ngrok.io/api";
export const photoURL = process.env.REACT_APP_API_URL + "uploads";
//export const photoURL = "https://f998-2405-201-d00b-137-4dd-4cab-f41c-449c.ngrok.io/uploads";
export const ngrok =
  "https://ce89-2405-201-d00b-b7-a9fb-5f-b1ba-29f0.ngrok.io/api";

export const socketUrl = process.env.REACT_APP_API_URL.slice(0, -1);
console.log(socketUrl)

export const cms_end_points = {
  about_us: "aboutus/details/",
  // testimonials: "testimonials/list",
  testimonials: "testimonials/getAll",
  city: "city/list",
  faq: "faq/list",
  privacy: "privacy/list",
  terms: "/terms_conditions/list",
  company: "",
  translation: "/custom_translation/getalls",
  getRandomProd: "/testimonials/getAll",
};

export const auth_end_points = {
  fulluserInfo: "/user/information",
  forgotPassword: "/user/forgot-password",
  userSignUp: "user/signup",
  producerSignUp: "producer/signup",
  signIn: "user/signin",
  verifyAccount: "user/account-verification",
  userDetails: "user/profile_details",
  logOut: "user/logout",
  resendOtp: "user/resend-email-otp",
  fbSignUp_user: "/user/socialsignin",
  socialLogIn: "/user/socialLogin",
  resetpassword: "resetpassword",
  verifyToken: "/user/verify",
};

export const product_end_points = {
  List_of_product_by_Id: "product/details",
  List_of_product: "product/list",
  Product_wishlist: "product/wishlist",
  addProduct: "product/save",
  editProdcuct: "product/edit",
  deleteProduct: "product/delete",
  list_of_prdct_producer: "product/get-by-producerId",
  changeStatus: "product/changeStatus",
  get_All_Producer_Products: "product/producers/list ",
};

export const setting_end_points = {
  List_of_setting: "setting/list",
};

export const subscription_end_points = {
  Cancel_Subscription: "subscription/cancel",
  Create_Subscription: "subscription/create",
  Get_Subscription_details: "subscription/details",
};

export const contact_end_point = {
  contact_us: "contactus/form/save",
};

export const meetingPage_end_point = {
  meetingList_join_meeting: "meeting/list",
  meetingListAll_getFavourite: "/meeting/fetchWishlist",
  createMeeting: "meeting/create",
  meetingWishList: "/meeting/saveWishlist",
  myList: "/meeting/mylist",
  upcomingList: "meeting/get-scheduled-weekly-meeting",
  meetingSchedule: "/schedule_meeting/create",
  meetingDetails: "meeting/details",
  participentInfo: "producers/profile",
  meetingListAll: "meeting/list-all",
  // similar_prdt_inMeeting: "producers-product-by-cat/list",
  similar_prdt_inMeeting: "product/product-by-cat/list",

  AcceptReq: "/meeting/AcceptMeetingReq",
  RejectReq: "/meeting/RejectMeetingReq",
  searchByEmail: "meeting/searchByEmail",
  joinMeeting: "/meeting/JoinMeeting",
  fetchScheduleDates: "/schedule_meeting",
  getActiveMeetingLocation: "meeting/available-meeting-list",
  getActiveMeetingLocationAll: "/meeting/get-all-available-meeting-list",
};

export const invite_member_end_point = {
  acceptInvite_producer: "invite_members/inviteAcceptProducer",
  invite_mem_wishlist: "invite_members/wishlist", //need an id
  invite_mem: "invite_members/invite_all",
  List_of_invite_members_by_Id: "invite_members/details", //need an id
  List_of_invite_members: "invite_members/list",
  Reject_invite_producer: "invite_members/inviteRejectProducer",
  allUserList: "/user/allList",
};

export const producers_end_point = {
  Producer_profile_update: "producer/updateprofile",
  all_producer_list: "producer/list",
  producerDetails: "producer/detail",
  getSimilarproducts: "product/get-by-producerId-cat-product",
  producerProducts: "/product/ofproducers",
  passwordUpdate: "user/changepassword",
  supplier_meetings: "/meeting/producer-meet",
};

export const user_end_pont = {
  updateProfile: "/user/update/profile",
};

export const category_end_point = {
  allCategory: "category/list",
};

export const search_end_point = {
  search_with_loaction: "chatsearch/chefsearch",
};

export const cart_end_point = {
  add_to_cart: "cart/store",
  getMyCart: "cart/getMyCart",
  getMyCart_prodCart: "/cart/getProdCart",
  RemoveFromCart: "cart/removeFromCart",
  UpdateCartItem: "cart/updateCartItem",
  add_to_cart_prodCart: "/cart/prod-store",
  RemoveFrom_prodCart: "/cart/ProdremoveFromCart",
  UpdateCartItem_prodCart: "/cart/updateCartItemForProd",
};

export const checkout_end_point = {
  saveCard: "/user/savecard",
  getUserCard: "/user/getcards",
  addCoupon: "cart/apply-Coupon-To-Cart",
};

export const order_end_point = {
  getTransactionFees: "/order/transaction_fee",
  orderCreate: "/order/create",
  getProducerOrders: "/order/getProducerOrder",
  getOrderSummary: "/order/getOrederSummary",
  statusChange: "/order/producer-order-status",
  getUserOrder: "/order/userOrder",
  myOrder: "/user/getUserOrders",
  getOrderDetails: "/order/userOrder-details",
  refund: "/stripe/refund",
  chart: "/order/sales-chart",
};

export const lists = {
  allUserList: "/user/allList",
};

export const subscription_end_point = {
  createSub: "/subscription/create",
};

export const stripe_end_point = {
  createAccount: "/stripe/create-connect-account",
  stripeCusDetails: "stripe/stripeCusDetails",
  subscriptionDetails: "/stripe/subscriptionDetails",
  cancel_sub: "/stripe/cancelSubscription",
  getAccountBalance: "stripe/retriveCustomer",
};
