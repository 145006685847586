import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { meetingPage_end_point } from "../../Api/Api_End_Points";

import privateAxiosInstance from "../../Api/AxiosInstance";

let cancelToken;

export const fetchMeetingList = createAsyncThunk(
  "fetchMeetingList",
  async (data) => {
    try {
      let token = localStorage.getItem("token");
      let x = "/meeting/list-Auth-user";
      let res;

      if (token === null) {
        res = await privateAxiosInstance.post(
          meetingPage_end_point.meetingListAll,
          data
        );
      } else {
        res = await privateAxiosInstance.post(x, data);
      }

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchMeetingListFavourite = createAsyncThunk(
  "fetchMeetingListFavourite",
  async (data) => {
    try {
      const res = await privateAxiosInstance.post(
        meetingPage_end_point.meetingListAll_getFavourite
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getActiveMeetingLocationList = createAsyncThunk(
  "getActiveMeetingLocationList",
  async (data) => {
    try {
     
     
      const res = await privateAxiosInstance.post(
        meetingPage_end_point.getActiveMeetingLocationAll,
        data,
     
      );

      //cancel previous request with axios

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchMeetingListMore = createAsyncThunk(
  "fetchMeetingListMore",
  async (data) => {
    try {
      const res = await privateAxiosInstance.post(
        meetingPage_end_point.meetingListAll,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const AddToFavourites = createAsyncThunk(
  "addToFavorites",
  async (data) => {
    try {
      const res = await privateAxiosInstance.post(
        `${meetingPage_end_point.meetingWishList}/${data.meeting_id}`,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const NearbyMeetingSearch = createAsyncThunk(
  "NearbyMeetingSearch",
  async (data) => {
    try {
      let x = "/meeting/nearbySearch";
      const res = await privateAxiosInstance.post(x, data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const removeFromFavourites = createAsyncThunk(
  "removeFromFavorites",
  async (id) => {
    try {
      // const res = await privateAxiosInstance.post(
      //   meetingPage_end_point.meetingWishList,
      //   id
      // );
      // return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  fetchMeetingListMoreStatus: "idle",
  addToFavoritesStatus: "idle",
  message: null,
  meetingList: null,
  totalPages: 0,
  getActiveMeetingLocationListStatus: "idle",
  getActiveMeetingLocationListData: null,
};

const MeetingPageSlice = createSlice({
  name: "meetingPageSlice",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(fetchMeetingList.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.meetingList = null;
        state.totalPages = 0;
      })
      .addCase(fetchMeetingList.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.meetingList = payload?.data;
          state.totalPages = payload?.pages;
        } else {
          state.meetingList = [];
          state.totalPages = 0;
        }
      })
      .addCase(fetchMeetingList.rejected, (state) => {
        state.message = "something went wrong";
        state.status = "idle";
      })

      // fetch favourite list

      .addCase(fetchMeetingListFavourite.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.meetingList = null;
      })
      .addCase(fetchMeetingListFavourite.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.meetingList = payload?.data;
          state.totalPages = payload?.pages;
        } else {
          state.meetingList = [];
          state.totalPages = 0;
        }
      })
      .addCase(fetchMeetingListFavourite.rejected, (state) => {
        state.message = "something went wrong";
        state.status = "idle";
      })

      //
      .addCase(fetchMeetingListMore.pending, (state) => {
        state.fetchMeetingListMoreStatus = "loading";
        state.message = null;
        // state.meetingList=null;
      })
      .addCase(fetchMeetingListMore.fulfilled, (state, { payload }) => {
        state.fetchMeetingListMoreStatus = "idle";
        state.message = payload?.message;

        if (payload?.status === 200) {
          // console.log(state.meetingList,"ss")
          if (state.meetingList?.length > 0) {
            state.meetingList = state.meetingList?.concat(payload?.data);
          }
          // state.meetingList = payload?.data;
          // console.log("d",state.meetingList)
          state.totalPages = payload?.pages;
        }
      })
      .addCase(fetchMeetingListMore.rejected, (state) => {
        state.message = "something went wrong";
        state.fetchMeetingListMoreStatus = "idle";
      })
      //
      .addCase(AddToFavourites.pending, (state) => {
        state.addToFavoritesStatus = "loading";
        state.message = null;
      })
      .addCase(AddToFavourites.fulfilled, (state, { payload }) => {
        state.addToFavoritesStatus = "idle";
        state.message = payload?.message;
      })
      .addCase(AddToFavourites.rejected, (state) => {
        state.addToFavoritesStatus = "idle";
        state.message = "something went wrong";
      })
      .addCase(NearbyMeetingSearch.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(NearbyMeetingSearch.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.meetingList = payload?.data;
          state.totalPages = 0;
        } else {
          state.meetingList = [];
        }
      })
      .addCase(NearbyMeetingSearch.rejected, (state) => {
        state.message = "something went wrong";
      })

      // get active meeting location list
      .addCase(getActiveMeetingLocationList.pending, (state) => {
        state.getActiveMeetingLocationListStatus = "loading";
        state.message = null;
        state.getActiveMeetingLocationListData = null;
      })
      .addCase(getActiveMeetingLocationList.fulfilled, (state, { payload }) => {
        state.getActiveMeetingLocationListStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.getActiveMeetingLocationListData = payload?.data?.data;
        } else {
          state.getActiveMeetingLocationListData = [];
        }
      })
      .addCase(getActiveMeetingLocationList.rejected, (state) => {
        state.message = "something went wrong";
        state.getActiveMeetingLocationListStatus = "idle";
      });
  },
});

export default MeetingPageSlice.reducer;

