import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  producers_end_point,
} from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";

export const fetchAllproducers = createAsyncThunk(
  "fetchAllProdcuer",
  async (data) => {
    try {
      console.log(data)
      const res = await axiosInstance.post(
        producers_end_point.all_producer_list,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProducerDetails = createAsyncThunk(
  "fetchProducerDetails",
  async (id) => {
    try {
      const res = await axiosInstance.post(
        `${producers_end_point.producerDetails}/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProducerDetails_meeting = createAsyncThunk(
  "getProducerDetails_meeting",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        producers_end_point.supplier_meetings,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProducerProducts = createAsyncThunk(
  "getProducerProducts",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        producers_end_point.producerProducts,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const get_similar_prdcts_producer = createAsyncThunk(
  "getSimilarProductproducer",
  async (data) => {
    try {
      console.log("prod");
      const res = await axiosInstance.post(
        producers_end_point.getSimilarproducts,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  fetchProducerstatus: "idle",
  ProducerList: null,
  ProducerListTotal: 0,
  details: null,
  products: null,
  products_total: 0,
  productsStatus: "idle",
  similarProducts_producer: [],
  getProducerDetailsStatus: "idle",
  similarProducts_producer_total: 0,
  similarProducts_producer_status: "idle",
  getProducerDetails_meeting_status:'idle',
  meetingList:null
};

const ProducerSlice = createSlice({
  name: "producerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getProducerDetails_meeting.pending, (state) => {
      state.getProducerDetails_meeting_status = "loading";
      state.message = null;
      state.meetingList = null;
      state.totalPages = 0;
    })
    .addCase(getProducerDetails_meeting.fulfilled, (state, { payload }) => {
      state.getProducerDetails_meeting_status = "idle";
      state.message = payload?.message;
      if (payload?.status === 200) {
        state.meetingList = payload?.data;
        state.totalPages = payload?.pages;
      } else {
        state.meetingList = [];
        state.totalPages = 0;
      }
    })
    .addCase(getProducerDetails_meeting.rejected, (state) => {
      state.message = "something went wrong";
      state.getProducerDetails_meeting_status = "idle";
    })

      .addCase(fetchAllproducers.pending, (state) => {
        state.fetchProducerstatus = "loading";
        state.products = null;
      })
      .addCase(fetchAllproducers.fulfilled, (state, { payload }) => {
        state.fetchProducerstatus = "idle";
        if (payload?.status === 200) {
          state.ProducerList = payload?.data;
          state.ProducerListTotal = payload?.pages;
        }
      })
      .addCase(fetchAllproducers.rejected, (state) => {
        state.fetchProducerstatus = "idle";
      })
      .addCase(getProducerDetails.pending, (state) => {
        state.getProducerDetailsStatus = "loading";
        state.details = null;
      })
      .addCase(getProducerDetails.fulfilled, (state, { payload }) => {
        state.getProducerDetailsStatus = "idle";
        state.details=null
        if (payload?.status === 200) {
          state.details = payload?.data;
        }
      })
      .addCase(getProducerDetails.rejected, (state) => {
        state.getProducerDetailsStatus = "rejected";
      })
      .addCase(get_similar_prdcts_producer.pending, (state) => {
        state.similarProducts_producer_status = "loading";
        state.similarProducts_producer = null;
      })
      .addCase(get_similar_prdcts_producer.fulfilled, (state, { payload }) => {
        state.similarProducts_producer_status = "idle";
        if (payload?.status === 200) {
          console.log(payload);
          state.similarProducts_producer = payload?.data;
          state.similarProducts_producer_total = payload?.pages;
        } else {
          state.similarProducts_producer = [];
        }
      })

      .addCase(get_similar_prdcts_producer.rejected, (state) => {
        state.similarProducts_producer_status = "idle";
      })

      .addCase(getProducerProducts.pending, (state) => {
        state.productsStatus = "loading";
        state.products = null;
        state.products_total = 0;
      })
      .addCase(getProducerProducts.fulfilled, (state, { payload }) => {
        state.productsStatus = "idle";
        if (payload?.status === 200) {
          state.products = payload?.data;
          state.products_total = payload?.pages;
        } else {
          state.products = [];
          state.products_total = 0;
        }
      })
      .addCase(getProducerProducts.rejected, (state) => {
        state.productsStatus = "rejected";
      });
  },
});

export default ProducerSlice.reducer;
