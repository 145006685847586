import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cms_end_points } from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";
const fs = require("fs");

export const getTrnaslation = createAsyncThunk(
  "get translaton",
  async (data) => {
    try {
      const res = await axiosInstance.post(cms_end_points.translation);
      // const res=await axiosInstance.post('http://localhost:2000/api/en')

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  joinMeeting: false,
  createMeeting: false,
  openMeetingDetails: false,
  OpenInviteModal: false,
  openDeleteModal: false,
  openProductModal: false,
  getTrnaslation_status: "idle",
  getTrnaslation_data: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    controlJoinMeetingModal: (state) => {
      state.joinMeeting = state.joinMeeting ? false : true;
    },
    controlCreateMeetingModal: (state,{payload}) => {
      state.createMeeting = payload;
    },
    controlOpenMeetingDetailsModal: (state) => {
      state.openMeetingDetails = state.openMeetingDetails ? false : true;
    },
    controlOpenInviteModal: (state) => {
      state.OpenInviteModal = state.OpenInviteModal ? false : true;
    },
    controlProductModal: (state) => {
      state.openProductModal = state.openProductModal ? false : true;
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(getTrnaslation.pending, (state) => {
        state.getTrnaslation_status = "loading";
      })
      .addCase(getTrnaslation.fulfilled, (state, { payload }) => {
        state.getTrnaslation_status = "idle";
        if (payload?.status === 200) {
          let temp = payload?.result;
          let temp2 = [];
          let obj = {};
          temp?.map((item) => {
            let key = item.englsih;
            let value = item?.finnish;

            obj[key] = value;
            temp2.push({
              ...item,
              keyword: key,
            });
          });

          // console.log(temp2)

          // fs.writeFile('language.json', JSON.stringify(obj));
          localStorage.setItem("language", JSON.stringify(obj));
          // console.log("obj", obj);
        }
      })
      .addCase(getTrnaslation.rejected, (state) => {});
  },
});

export const {
  controlJoinMeetingModal,
  controlCreateMeetingModal,
  controlOpenMeetingDetailsModal,
  controlOpenInviteModal,
  controlProductModal,
} = globalSlice.actions;
export default globalSlice.reducer;
