import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingSpinner = () => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", padding: "10px 20px" }}
    >
      <CircularProgress size={40} color="success" />
    </Box>
  );
};

export const PageLoading = ({progress}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 20px",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={137}
          height={50}
          className="text-center"
        >
          <text
            id="Potatoboy"
            transform="translate(0 39)"
            fill="#00ba88"
            fontSize="30"
            fontFamily="'Chewy', cursive"
          >
            <tspan x="0" y="0">
                Potatoboy -{progress}
            </tspan>
          </text>
        </svg>
      </div>

      <CircularProgress
        className="mt-2"
        style={{ marginLeft: "-8px" }}
        size={20}
        color="success"
      />
    </Box>
  );
};

export const BSspinner = () => {
  return (
    <span
      className="spinner-border spinner-border-sm mx-2"
      role="status"
      aria-hidden="true"
    ></span>
  );
};

export default LoadingSpinner;
