import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { stripe_end_point } from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";

export const getStripeCustomerDetails = createAsyncThunk(
  "getStripeCustomerDetails",
  async (data) => {
    try {
      const res = await axiosInstance.get(stripe_end_point.stripeCusDetails);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  "createStripeAccount",
  async (data) => {
    try {
      const res = await axiosInstance.post(stripe_end_point.createAccount);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getSubscriptionDetails = createAsyncThunk(
  "getSubscriptionDetails",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        stripe_end_point.subscriptionDetails
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAccountBalance = createAsyncThunk(
  "getAccountBalance",
  async (data) => {
    try {
      const res = await axiosInstance.post(stripe_end_point.getAccountBalance);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "cancelSubscription",
  async (data) => {
    try {
      const res = await axiosInstance.post(stripe_end_point.cancel_sub, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const chnageCard = createAsyncThunk(
  "chnageCard",
  async (data) => {
    try {
      let x="/subscription/update-card"
      const res = await axiosInstance.post(x, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);



const initialState = {
  status: "idle",
  message: null,
  getStripeCustomerDetailsStatus: "idle",
  getStripeCustomerDetailsData: null,
  getSubscriptionDetailsStatus: "idle",
  getSubscriptionDetailsData: null,
  cancelSubscriptionStatus: "idle",
  cancelSubscriptionRequest: false,
  getAccountBalanceStatus: "idle",
  getAccountBalanceData: null,
  createStripeAccountStatus: "idle",
  createStripeAccountData: null,
  chnageCardStatus: "idle",
  chnageCardData: null,
};

const DashboardStripeSlice = createSlice({
  name: "dashboard_setting",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders.addCase(createStripeAccount.pending, (state) => {
      state.createStripeAccountStatus = "loading";
      state.cancelSubscriptionRequest = false;
    });
    builders.addCase(
      createStripeAccount.fulfilled,
      (state, { payload }) => {
        state.createStripeAccountStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          window.location.href = payload?.data;
        }else{
          toast("Something went wrong", { type: "error" });
        }
      }
    );
    builders.addCase(createStripeAccount.rejected, (state) => {
      state.createStripeAccountStatus = "idle";
      state.message = "something went wrong";
    });



    builders.addCase(getStripeCustomerDetails.pending, (state) => {
      state.getStripeCustomerDetailsStatus = "loading";
      state.cancelSubscriptionRequest = false;
      state.getStripeCustomerDetailsData=null
    });
    builders.addCase(
      getStripeCustomerDetails.fulfilled,
      (state, { payload }) => {
        state.getStripeCustomerDetailsStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.getStripeCustomerDetailsData = payload?.data;
        
        }
      }
    );
    builders.addCase(getStripeCustomerDetails.rejected, (state) => {
      state.getStripeCustomerDetailsStatus = "idle";
      state.message = "something went wrong";
    });

    builders.addCase(getSubscriptionDetails.pending, (state) => {
      state.getSubscriptionDetailsStatus = "loading";
      state.cancelSubscriptionRequest = false;
    });
    builders.addCase(getSubscriptionDetails.fulfilled, (state, { payload }) => {
      state.getSubscriptionDetailsStatus = "idle";
      state.message = payload?.message;
      if (payload?.status === 200) {
        state.getSubscriptionDetailsData = payload?.data;
      }
    });
    builders.addCase(getSubscriptionDetails.rejected, (state) => {
      state.getSubscriptionDetailsStatus = "idle";
      state.message = "something went wrong";
    });

    builders.addCase(cancelSubscription.pending, (state) => {
      state.cancelSubscriptionStatus = "loading";
      state.cancelSubscriptionRequest = false;
    });
    builders.addCase(cancelSubscription.fulfilled, (state, { payload }) => {
      state.cancelSubscriptionStatus = "idle";
      state.message = payload?.message;
      if (payload?.status === 200) {
        state.cancelSubscriptionRequest = true;
        toast(payload?.message, { type: "success" });
      } else {
        toast(payload?.message, { type: "error" });
      }
    });
    builders.addCase(cancelSubscription.rejected, (state) => {
      state.cancelSubscriptionStatus = "idle";
      state.message = "something went wrong";
    });

    builders.addCase(getAccountBalance.pending, (state) => {
      state.getAccountBalanceStatus = "loading";
      state.getAccountBalanceData = null;
    });
    builders.addCase(getAccountBalance.fulfilled, (state, { payload }) => {
      state.getAccountBalanceStatus = "idle";
      state.message = payload?.message;
      if (payload?.status === 200) {
        state.getAccountBalanceData = payload?.data;
      } else {
      }
    });
    builders.addCase(getAccountBalance.rejected, (state) => {
      state.getAccountBalanceStatus = "idle";
      state.message = "something went wrong";
    });
    builders.addCase(chnageCard.pending, (state) => {
      state.chnageCardStatus = "loading";
      state.chnageCardData = null;
    }
    );
    builders.addCase(chnageCard.fulfilled, (state, { payload }) => {
     
      state.message = payload?.message;
      if (payload?.status === 200) {
        state.chnageCardStatus = "success";
        state.chnageCardData = payload?.data;
        toast(payload?.message, { type: "success" });
      } else {
        state.chnageCardStatus = "error";
         toast(payload?.message, { type: "error" });
      }
    }
    );
    builders.addCase(chnageCard.rejected, (state) => {
      state.chnageCardStatus = "error";
      state.message = "something went wrong";
    }
    );

  },
});

export default DashboardStripeSlice.reducer;
