import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { order_end_point } from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";
import produce from "immer";
export const getMyOrders = createAsyncThunk("getMyOrders", async (data) => {
  try {
    const res = await axiosInstance.post(
      order_end_point.getProducerOrders,
      data
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getOrderSummary = createAsyncThunk(
  "getOrderSummary",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        order_end_point.getOrderSummary,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const orderStatusChange = createAsyncThunk(
  "orderStatusChange",
  async (data) => {
    try {
      const res = await axiosInstance.post(order_end_point.statusChange, data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const refundRequest = createAsyncThunk("refundRequest", async (data) => {
  try {
    const res = await axiosInstance.post(order_end_point.refund, data);

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const FetchOrderChartData = createAsyncThunk(
  "orderChartData",
  async (data) => {
    try {
      const res = await axiosInstance.post(order_end_point.chart, data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  dashboardOrders: null,
  orderStatusChanged: false,
  orderSummary: null,
  orderSummaryStatus: "idle",
  refundRequestStatus: "idle",
  refundRequestData: false,
  orderChartDataStatus: "idle",
  orderChartData: null,
};

const DashboardOrderSlice = createSlice({
  name: "dashboardOrder",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(getMyOrders.pending, (state) => {
        state.status = "loading";
        state.dashboardOrders = null;
      })
      .addCase(getMyOrders.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          state.dashboardOrders = payload?.data;
        }
      })
      .addCase(getMyOrders.rejected, (state) => {
        state.status = "idle";
      })

      //order status chnage
      .addCase(orderStatusChange.pending, (state) => {
        // state.status = "loading";
        state.orderStatusChanged = false;
      })
      .addCase(orderStatusChange.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          // state.orderStatusChanged = true;
          const order = current(state.dashboardOrders);
          produce(order.data, (draftState) => {
            let index = order.data.findIndex(
              (item) => item?._id === payload?.data?._id
            );

            draftState[index].order_status = payload?.data?.order_status;
            state.dashboardOrders.data = current(draftState);
            toast(payload?.message, { type: "success" });
          });
        }
      })
      .addCase(orderStatusChange.rejected, (state) => {
        state.status = "idle";
      })
      //get order summary
      .addCase(getOrderSummary.pending, (state) => {
        state.orderSummaryStatus = "loading";
        state.orderSummary = null;
        state.refundRequestData = false;
      })
      .addCase(getOrderSummary.fulfilled, (state, { payload }) => {
        state.orderSummaryStatus = "idle";
        if (payload?.status === 200) {
          state.orderSummary = payload?.data[0];
        }
      })
      .addCase(getOrderSummary.rejected, (state) => {
        state.orderSummaryStatus = "idle";
      })
      //refund req
      .addCase(refundRequest.pending, (state) => {
        state.refundRequestStatus = "loading";
        state.refundRequestData = false;
      })
      .addCase(refundRequest.fulfilled, (state, { payload }) => {
        state.refundRequestStatus = "idle";
        if (payload?.status === 200) {
          state.refundRequestData = true;
          const order = current(state.dashboardOrders);
          produce(order.data, (draftState) => {
            let index = order.data.findIndex(
              (item) => item?._id === payload?.data?._id
            );

            draftState[index].payment_status = payload?.data?.payment_status;
            state.dashboardOrders.data = current(draftState);
            toast(payload?.message, { type: "success" });
          });
        } else {
          toast(payload?.message, { type: "error" });
        }
      })
      .addCase(refundRequest.rejected, (state) => {
        state.refundRequestStatus = "idle";
      })
      //order chart data
      .addCase(FetchOrderChartData.pending, (state) => {
        state.orderChartDataStatus = "loading";
        state.orderChartData = null;
      })
      .addCase(FetchOrderChartData.fulfilled, (state, { payload }) => {
        state.orderChartDataStatus = "idle";
        if (payload?.status === 200) {
          state.orderChartData = payload?.data;
        }
      })
      .addCase(FetchOrderChartData.rejected, (state) => {
        state.orderChartDataStatus = "idle";
      });
  },
});

export default DashboardOrderSlice.reducer;
