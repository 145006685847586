import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { auth_end_points } from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";

export const SignInRequest = createAsyncThunk("/signin", async (user) => {
  try {
    const res = await axiosInstance.post(auth_end_points.signIn, user);

    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});
export const verifyToken = createAsyncThunk("/verifyToken", async (user) => {
  try {
    const res = await axiosInstance.post(auth_end_points.verifyToken);

    return res.data;
  } catch (error) {
    console.log("error", error);
  }
});

//set display none before inside settimeout

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data) => {
    try {
      const res = await axiosInstance.post(auth_end_points.forgotPassword, {
        email: data,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUserDetails = createAsyncThunk("userDetails", async () => {
  try {
    const res = await axiosInstance.post(auth_end_points.userDetails);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const getUserDetailsFull = createAsyncThunk(
  "getUserDetailsFull",
  async () => {
    try {
      const res = await axiosInstance.get(auth_end_points.fulluserInfo);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const LogOut = createAsyncThunk("logOut", async () => {
  try {
    const res = await axiosInstance.post(auth_end_points.logOut);

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const facebookLogIn = createAsyncThunk(
  "facebookSignIn",
  async (data) => {
    try {
      const res = await axiosInstance.post(auth_end_points.socialLogIn, data);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const setNewPassword = createAsyncThunk("newpassword", async (data) => {
  try {
    const res = await axiosInstance.post(
      `${auth_end_points.resetpassword}/${data?.id}`,
      { password: data?.password }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
});

const initialState = {
  status: "idle",
  isLoggedIn: false,
  res: null,
  token: "",
  message: null,
  user: null,
  userDetails: null,
  forgotpass_msg: null,
  forgot_pass_res: false,
  facebookLogin: false,
  logOut: false,
  forgotpass_msg_err: null,
  newPasswordSaved: null,
  logOutStatus: "idle",
  facebookLogIn_status: "idle",
  forgotPasswordstatus: "idle",
  getUserDetailsFullstatus: "idle",
  getUserDetailsFulldata: null,
  redirectTo: "",
};

export const SignInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {
    isLogeedIn: (state) => {
      if (localStorage.getItem("token")) {
        state.isLoggedIn = true;
      }
    },
    getUserDetailsFromSession: (state) => {
      if (localStorage.getItem("userDetails")) {
        state.userDetails = JSON.parse(localStorage.getItem("userDetails"));
      }
    },
    setLogout: (state, { payload }) => {
      state.logOutStatus = payload;
    },
    reset_forgot_pass_res: (state, { payload }) => {
      state.forgot_pass_res = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SignInRequest.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.user = null;
        state.userDetails = null;
        state.message = null;
        localStorage.clear();
      })

      .addCase(SignInRequest.fulfilled, (state, { payload }) => {
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.status = "success";
          localStorage.setItem("token", payload?.token);
          state.isLoggedIn = true;

          

          if (payload?.data?.role?.role === "producers") {
            payload?.data?.stripeAccountId === ""
              ? (state.redirectTo = "/user-authentication/payment-setup")
              : (state.redirectTo = "/dashboard");
          } else {
            if (payload?.data?.isSignupCompleted) {
              state.redirectTo = "/";
            } else {
              state.redirectTo = "/user-authentication/verify";
            }
          }

          //  toast.success(state.message, { type: "success" });
        } else {
          state.status = "error";
          state.isLoggedIn = false;
          // Toast2(payload?.message, "error");
          // toast.error(state.message, { type: "error" });
        }
      })
      .addCase(SignInRequest.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.status = "error";
        state.message = "Something went wrong";
        toast(state.message, { type: "error" });
      })
      .addCase(getUserDetails.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.res = payload?.status;

        if (payload?.status === 200) {
          let temp = payload?.data[0];
          temp = {
            ...temp,
            stripeCustomerId: payload?.data[0]?.stripeAccountId,
          };
          state.userDetails = temp;

          localStorage.setItem("userDetails", JSON.stringify(temp));
        }
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.message = "Something went wrong";
      })
      .addCase(LogOut.pending, (state) => {
        state.logOutStatus = "loading";
        state.message = null;
      })
      .addCase(LogOut.fulfilled, (state, { payload }) => {
        localStorage.removeItem("token");
        localStorage.removeItem("userDetails");
        localStorage.clear();
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.user = null;
          state.userDetails = null;

          state.logOut = true;
          state.logOutStatus = "success";
          toast("Log out successful", { type: "success" });
          window.location.pathname = "/user-authentication";
          // state.redirectTo = "/user-authentication";
          //
          state.isLoggedIn = false;
        } else {
          state.logOutStatus = "error";
          // toast(payload?.message, { type: "error" });
        }
      })
      .addCase(LogOut.rejected, (state) => {
        state.message = "Something went wrong";
        state.logOutStatus = "idle";
        toast(state.message, { type: "error" });
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordstatus = "loading";
        state.message = null;
        state.forgotpass_msg = null;
        state.forgot_pass_res = false;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.forgotPasswordstatus = "idle";

        if (payload?.status === 200) {
          state.forgot_pass_res = true;
          state.forgotpass_msg = payload?.message;
        } else {
          state.forgotpass_msg_err = payload?.message;
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.message = "Something went wrong";
        state.forgotPasswordstatus = "idle";
        toast(state.message, { type: "error" });
      })

      .addCase(facebookLogIn.pending, (state) => {
        state.facebookLogIn_status = "loading";
        state.message = null;
        state.facebookLogin = false;
      })
      .addCase(facebookLogIn.fulfilled, (state, { payload }) => {
        state.facebookLogIn_status = "idle";

        if (payload?.status === 200) {
          state.status = "success";

          if (payload?.userRole?.role === "producers") {
            payload?.userDetails?.stripeAccountId === ""
              ? (state.redirectTo = "/user-authentication/payment-setup")
              : (state.redirectTo = "/dashboard");
          } else {
            state.redirectTo = "/";
          }

          //  toast.success(state.message, { type: "success" });
          localStorage.setItem("token", payload?.token);
        } else {
          state.status = "error";
          state.isLoggedIn = false;
          // Toast2(payload?.message, "error");
          // toast.error(state.message, { type: "error" });
        }

        // if (payload?.status === 200) {
        //   toast("Log in successful", { type: "success" });
        //   localStorage.setItem("token", payload?.token);
        //   // state.facebookLogin=true;
        //   state.isLoggedIn = true;
        // } else {
        //   toast(payload?.message, { type: "error" });
        // }
      })
      .addCase(facebookLogIn.rejected, (state, action) => {
        state.facebookLogIn_status = "idle";
        state.message = "Something went wrong";
        toast(state.message, { type: "error" });
      })

      //New password
      .addCase(setNewPassword.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.newPasswordSaved = false;
      })
      .addCase(setNewPassword.fulfilled, (state, { payload }) => {
        state.status = "idle";

        if (payload?.status === 200) {
          localStorage.setItem("token", payload?.token);
          state.isLoggedIn = true;

          if (payload?.data?.role?.role === "producers") {
            payload?.data?.stripeAccountId === ""
              ? (state.redirectTo = "/user-authentication/payment-setup")
              : (state.redirectTo = "/dashboard");
          } else {
            state.redirectTo = "/";
          }

          toast(payload?.message, { type: "success" });
          state.newPasswordSaved = true;
        } else {
          toast(payload?.message, { type: "error" });
          state.newPasswordSaved = false;
        }
      })
      .addCase(setNewPassword.rejected, (state, action) => {
        state.message = "Something went wrong";
        state.newPasswordSaved = false;
        toast(state.message, { type: "error" });
      })
      //verify token
      .addCase(verifyToken.pending, (state) => {
        state.verifyTokenstatus = "loading";
        state.message = null;
      })
      .addCase(verifyToken.fulfilled, (state, { payload }) => {
        state.verifyTokenstatus = "idle";

        if (payload?.status === 200) {
        } else {
          state.user = null;
          state.userDetails = null;
          state.message = null;
          state.logOut = true;
          localStorage.clear();
          window.location.pathname = "/user-authentication";
          state.isLoggedIn = false;
        }
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.message = "Something went wrong";
      })
      .addCase(getUserDetailsFull.pending, (state) => {
        state.getUserDetailsFullstatus = "loading";
        state.getUserDetailsFulldata = null;
        state.message = null;
      })
      .addCase(getUserDetailsFull.fulfilled, (state, { payload }) => {
        state.getUserDetailsFullstatus = "idle";
        if (payload?.status === 200) {
          state.getUserDetailsFulldata = payload?.data[0];
        }
      })
      .addCase(getUserDetailsFull.rejected, (state, action) => {
        state.getUserDetailsFullstatus = "idle";
      });
  },
});

export const {
  isLogeedIn,
  getUserDetailsFromSession,
  setLogout,
  reset_forgot_pass_res,
} = SignInSlice.actions;
export default SignInSlice.reducer;
