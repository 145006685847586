import { configureStore } from "@reduxjs/toolkit";
import AboutUsSlice from "../AboutUsSlice/AboutUsSlice";
import SignInSlice from "../AuthSlice/SignInSlice/SignInSlice";
import SignUpSlice from "../AuthSlice/SignUpSlice/SignUpSlice";
import cartSlice from "../cartSlice/cartSlice";
import CheckoutSlice from "../checkoutSlice/CheckoutSlice";
import CitySlice from "../citySlice/CitySlice";
import ContactUsSlice from "../ContactUsSlice/ContactUsSlice";
import Customer_order_slice from "../customerOrders/Customer_order_slice";
import dash_notification_slice from "../DashboardSlices/dashboardNotificationSlice/dash_notification_slice";
import DashboardMeetingSlice from "../DashboardSlices/Dashboard_meeting_slices/DashboardMeetingSlice";
import Dashboard_order_slice from "../DashboardSlices/dashboard_order_slice/Dashboard_order_slice";
import DashboardProductSice from "../DashboardSlices/Dashboard_product_slice/DashboardProductSice";
import DashboardSettingSlice from "../DashboardSlices/Dashboard_setting_slice/DashboardSettingSlice";
import DashboardStripeSlice from "../DashboardSlices/dashboard_stripe/DashboardStripeSlice";
import GlobalSlice from "../GlobalSlice/GlobalSlice";
import HomePageSlice from "../HomePageSlice/HomePageSlice";
import meetingDetailsSlice from "../meetingDetailsSlice/meetingDetailsSlice";
import MeetingPageSlice from "../meetingPageSlice/MeetingPageSlice";

import OrderSlice from "../orderSlice/OrderSlice";
import ProducerSlice from "../producerSlice/ProducerSlice";
import TestimonialSlice from "../TestimonialSlice/TestimonialSlice";
import UserMyMeetingSlice from "../UserSlice/UserMyMeetingSlice/UserMyMeetingSlice";
import userMyProfileSlice from "../UserSlice/userMyProfileSlice/userMyProfileSlice";


const Store = configureStore({
  reducer: {
    global:GlobalSlice,
    signUp: SignUpSlice,
    signIn:SignInSlice,
    aboutUs:AboutUsSlice,
    testimonials:TestimonialSlice,
    contactUs:ContactUsSlice,
    city:CitySlice,
    meetingPage:MeetingPageSlice,
    meetingDetails:meetingDetailsSlice,
    userMeeting:UserMyMeetingSlice,
    userProfile:userMyProfileSlice,
    dash_meeting:DashboardMeetingSlice,
    dash_setting:DashboardSettingSlice,
    dash_product:DashboardProductSice,
    dash_order:Dashboard_order_slice,
    home:HomePageSlice,
    cart:cartSlice,
    producerPage:ProducerSlice,
    checkout:CheckoutSlice,
    order:OrderSlice,
    customerOrders:Customer_order_slice,
    dash_stripe:DashboardStripeSlice,
    dash_notification:dash_notification_slice
    
  },
});

export default Store;
