import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  meetingPage_end_point,
   product_end_points,
} from "../../Api/Api_End_Points";
import { fetchCategory } from "../../Api/Api_functions";
import axiosInstance from "../../Api/AxiosInstance";

export const fetchCategoryList = createAsyncThunk("fetchCategory", async () => {
  const res = await fetchCategory();
  return res.data;
});

export const fetchSimilarProducts = createAsyncThunk(
  "fetchSimilarProducts",
  async (data) => {
    try {
    
      const res = await axiosInstance.post(
        meetingPage_end_point.similar_prdt_inMeeting,
        data
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchProducerProducts = createAsyncThunk(
  "fetchProducerProducts",
  async (data) => {
    try {
      const res = await axiosInstance.post(
        product_end_points.get_All_Producer_Products,
        data
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchMeetingDetails = createAsyncThunk(
  "fetchMeetingDetails",
  async (id) => {
    try {
      const res = await axiosInstance.post(
        `${meetingPage_end_point.meetingDetails}/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  categoryList: null,
  categoryListStatus: "idle",
  msg: null,
  meeetingDetails: null,
  meetingDetailsStatus: "idle",
  allProducts: null,
  similarProducts: null,
  similarProductsStatus: "idle",
  totalPages: 0,
  totalPages_SimilarProducts: 0,
  similarProductsDetails:null
};

const meetingDetailsSlice = createSlice({
  name: "meetingDetailsSlice",
  initialState,
  reducers: {
    displaySimilarProductsDetails: (state, {payload}) => {
        state.similarProductsDetails=payload;
        state.similarProducts=null;
        state.totalPages_SimilarProducts=0
    }
  },
  extraReducers: (builders) => {
    builders
      .addCase(fetchCategoryList.pending, (state) => {
        state.categoryListStatus = "loading";
      })
      .addCase(fetchCategoryList.fulfilled, (state, { payload }) => {
        state.msg = payload?.message;
        state.categoryListStatus = "idle";
        if (payload?.status === 200) {
          let temp=payload?.data;

           if(temp?.length>0){
            [temp[5], temp[6]] = [temp[6], temp[5]];
           }
         
          console.log(temp,"temp")
          state.categoryList = payload?.data;
        }
      })
      .addCase(fetchCategoryList.rejected, (state) => {
        state.categoryListStatus = "idle";
        state.msg = "something Went wrong";
      })
      //Fetch meetings
      .addCase(fetchMeetingDetails.pending, (state) => {
        state.meetingDetailsStatus = "loading";
        state.msg = null;
        state.meeetingDetails = null;
      })
      .addCase(fetchMeetingDetails.fulfilled, (state, { payload }) => {
        state.msg = payload?.message;

        state.meetingDetailsStatus = "idle";
        if (payload?.status === 200) {
          state.meeetingDetails = payload?.data;
        }
      })
      .addCase(fetchMeetingDetails.rejected, (state) => {
        state.meetingDetailsStatus = "idle";
        state.msg = "something Went wrong";
      })
      // get products
      .addCase(fetchProducerProducts.pending, (state) => {
        state.status = "loading";
        state.msg = null;
      })
      .addCase(fetchProducerProducts.fulfilled, (state, { payload }) => {
        state.msg = payload?.message;

        state.status = "idle";
        if (payload?.status === 200) {
          state.allProducts = payload?.data;
          state.totalPages = payload?.pages;
        } else {
          state.allProducts = [];
        }
      })
      .addCase(fetchProducerProducts.rejected, (state) => {
        state.status = "idle";
        state.msg = "something Went wrong";
      })
      //fetch similiar product
      .addCase(fetchSimilarProducts.pending, (state) => {
        state.similarProductsStatus = "loading";
        state.msg = null;
      })
      .addCase(fetchSimilarProducts.fulfilled, (state, { payload }) => {
     
        state.msg = payload?.message;

        state.similarProductsStatus = "idle";
        if (payload?.status === 200) {
          state.similarProducts = payload?.data;
          state.totalPages_SimilarProducts = payload?.pages;
        } else {
          state.similarProductsStatus = [];
        }
      })
      .addCase(fetchSimilarProducts.rejected, (state) => {
        state.similarProductsStatus = "idle";
        state.msg = "something Went wrong";
      });
  },
});

export const {displaySimilarProductsDetails}=meetingDetailsSlice.actions

export default meetingDetailsSlice.reducer;
