import axios from "axios";
import { baseURL } from "./Api_End_Points";

let axiosInstance = axios.create({
  baseURL,
});



axiosInstance.interceptors.request.use(
  async function (config) {
    const rememberMe = localStorage.getItem("rememberMe");

    const token = localStorage.getItem("token");

    if (token !== null || token !== undefined) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

export const getPosition = axios.create({
  baseURL:
    "http://api.positionstack.com/v1/forward?access_key=833bcf2f50d20e1cb6768f8a035da970&query=",
});

export default axiosInstance;
