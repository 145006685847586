import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkout_end_point } from "../../Api/Api_End_Points";
import axiosInstance from "../../Api/AxiosInstance";

export const saveCard = createAsyncThunk("savecard", async (card) => {
  try {
    const res = await axiosInstance.post(checkout_end_point.saveCard, card);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchSavedCard = createAsyncThunk(
  "fetchSavecard",
  async (card) => {
    try {
      const res = await axiosInstance.get(checkout_end_point.getUserCard);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const applyCoupon = createAsyncThunk("applyCoupon", async (data) => {
  try {
    const res = await axiosInstance.post(checkout_end_point.addCoupon, {
      coupon: data,
    });
    let payload = {
      data: res.data,
      coupon: data,
    };
    return payload;
  } catch (error) {
    console.log(error);
  }
});
export const newCheckoutProcess = createAsyncThunk(
  "newCheckoutProcess",
  async (data) => {
    try {
      const res = await axiosInstance.post(checkout_end_point.addCoupon, {
        coupon: data,
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  savedCard: [],
  message: null,
  resStatus: null,
  couponMsg: null,
  isValid: false,
  applyCouponStatus: "idle",
  coupondata: null,
  newCheckoutProcess_status: "idle",
  newCheckoutProcess_data: null,
  newCheckoutProcess_error: null,
};
const checkoutSlice = createSlice({
  name: "chckout_slice",
  initialState,
  reducers: {
    resetCouponData: (state) => {
      state.coupondata = null;
      state.isValid=false;
      state.applyCouponStatus = "idle";
      sessionStorage.removeItem("coupon_code");
      sessionStorage.removeItem("coupon");
      sessionStorage.removeItem("discount_amount");
    }

  },
  extraReducers: (builders) => {
    builders
      .addCase(saveCard.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.resStatus = null;
      })
      .addCase(saveCard.fulfilled, (state, { payload }) => {
        state.message = payload?.message;
        state.status = "idle";
        state.resStatus = payload?.status;
      })
      .addCase(saveCard.rejected, (state) => {
        state.message = "something went wrong";
        state.status = "idle";
        state.resStatus = 500;
      })
      .addCase(fetchSavedCard.pending, (state) => {
        state.status = "loading";
        state.message = null;
        state.resStatus = null;
      })
      .addCase(fetchSavedCard.fulfilled, (state, { payload }) => {
        state.message = payload?.message;
        state.status = "idle";

        state.resStatus = payload?.status;

        if (payload?.status === 200) {
          state.savedCard = payload?.data;
        }
      })
      .addCase(fetchSavedCard.rejected, (state) => {
        state.message = "something went wrong";
        state.status = "idle";
        state.resStatus = 500;
      })

      //apply coupon

      .addCase(applyCoupon.pending, (state) => {
        state.applyCouponStatus = "loading";
        state.couponMsg = null;
        state.isValid = false;
        state.coupondata == null;
      })
      .addCase(applyCoupon.fulfilled, (state, { payload }) => {
        state.couponMsg = payload?.data?.message;
        state.applyCouponStatus = "idle";
        if (payload?.data?.status === 200) {
          state.isValid = true;
          state.coupondata = payload?.data?.data;
          sessionStorage.setItem("coupon", payload?.data?.data?.coupon_type);
          sessionStorage.setItem(
            "discount_amount",
            payload?.data?.data?.discount
          );
          sessionStorage.setItem(
            "coupon_code",
            payload?.data?.data?.name
          );
        } else {
          state.isValid = false;
          sessionStorage.removeItem("coupon");
          sessionStorage.removeItem("discount_amount");
        }

        state.resStatus = payload?.status;
      })
      .addCase(applyCoupon.rejected, (state, { payload }) => {
        state.couponMsg = payload?.message;
        state.applyCouponStatus = "idle";
        state.resStatus = 500;
      })

      //new checkout process

      .addCase(newCheckoutProcess.pending, (state) => {
        state.newCheckoutProcess_status = "loading";
        state.newCheckoutProcess_data = null;
        state.newCheckoutProcess_error = null;
      })
      .addCase(newCheckoutProcess.fulfilled, (state, { payload }) => {
        state.newCheckoutProcess_status = "idle";
        if (payload?.status === 200) {
          state.newCheckoutProcess_data = payload?.data;
          state.newCheckoutProcess_error = null;
          window.location.href = "/checkout/success";
        } else {
          window.location.href = "/";
        }
      })
      .addCase(newCheckoutProcess.rejected, (state, { payload }) => {
        state.newCheckoutProcess_status = "idle";
        state.newCheckoutProcess_data = null;
        state.newCheckoutProcess_error = payload?.message;
      });
  },
});

export const { resetCouponData } = checkoutSlice.actions;
export default checkoutSlice.reducer;
