import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  category_end_point,
  product_end_points,
} from "../../../Api/Api_End_Points";
import axiosInstance from "../../../Api/AxiosInstance";
export const addNewProduct = createAsyncThunk("addNewProduct", async (data) => {
  try {
    const res = await axiosInstance.post(product_end_points.addProduct, data);

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchCategory = createAsyncThunk("fetchCategory", async () => {
  try {
    const res = await axiosInstance.post(category_end_point.allCategory);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const myProductList = createAsyncThunk("fetchProducts", async (data) => {
  try {
    const res = await axiosInstance.post(
      product_end_points.list_of_prdct_producer,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const deleteProduct = createAsyncThunk("deleteProduct", async (data) => {
  try {
    const res = await axiosInstance.post(
      product_end_points.deleteProduct,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const editProduct = createAsyncThunk("editProduct", async (payload) => {
  try {
    const res = await axiosInstance.post(
      `${product_end_points.editProdcuct}/${payload.id}`,
      payload?.data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  status: "idle",
  category: [],
  message: null,
  productList: null,
  productListStatus: "idle",
  deleted: false,
  edited: false,
  added: false,
  addNewProductStatus: "idle",
  totalPage: 0,
  editStatus:"idle",
  deleteStatus:"idle",
};

export const DashboardProductSlice = createSlice({
  name: "dashboardProduct",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      //fetch category
      .addCase(fetchCategory.pending, (state) => {
        state.status = "loading";
        state.message = null;
      })
      .addCase(fetchCategory.fulfilled, (state, { payload }) => {
        state.status = "idle";
        if (payload?.status === 200) {
          state.category = payload?.data;
        }
      })
      .addCase(fetchCategory.rejected, (state) => {
        state.status = "idle";
        state.message = "something went wrong";
      })
      //add new Product
      .addCase(addNewProduct.pending, (state) => {
        state.addNewProductStatus = "loading";
        state.message = null;
        state.added = false;
      })
      .addCase(addNewProduct.fulfilled, (state, { payload }) => {
        state.addNewProductStatus = "idle";
        if (payload?.status === 200) {
          state.added = true;

          // produce(current(state.productList), (draftState) => {
          //   draftState.unshift(payload?.data);
          //   state.productList = current(draftState);
          // });

          toast("Product Added", { type: "success" });
        } else {
          toast(state?.message, { type: "error" });
        }
      })
      .addCase(addNewProduct.rejected, (state) => {
        state.addNewProductStatus = "idle";
        state.message = "something went wrong";
      })

      // fetch products
      .addCase(myProductList.pending, (state) => {
        state.productListStatus = "loading";
        state.message = null;
        state.added = false;
        state.deleted=false;
        state.edited=false;
        state.productList = null;
        state.totalPage = 0;
      })
      .addCase(myProductList.fulfilled, (state, { payload }) => {
        state.productListStatus = "idle";
        if (payload?.status === 200) {
          state.productList = payload?.data?.data;
          state.totalPage = payload?.data?.pageCount;
        }
      })
      .addCase(myProductList.rejected, (state) => {
        state.productListStatus = "idle";
        state.message = "something went wrong";
      })

      //delete product
      .addCase(deleteProduct.pending, (state) => {
        state.deleteStatus = "loading";
        state.message = null;
        state.deleted = false;
      })
      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        state.deleteStatus = "idle";
        state.message = payload?.message;
        if (payload?.status === 200) {
          state.deleted = true;
          toast("Product deleted", { type: "success" });
        } else {
          toast(state?.message, { type: "error" });
        }
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.deleteStatus = "idle";
        state.message = "something went wrong";
      })
      //edit product
      .addCase(editProduct.pending, (state) => {
        state.editStatus = "loading";
        state.message = null;
        state.edited = false;

      })
      .addCase(editProduct.fulfilled, (state, { payload }) => {
        state.editStatus = "idle";

        if (payload?.status === 200) {
          const productList = current(state.productList);
          // produce(productList, (draftState) => {
          //   let index = productList.findIndex(
          //     (item) => item?._id === payload?.data?._id
          //   );

          //   draftState[index] = payload?.data;
          //   state.productList = current(draftState);
          //   
          // });
          toast(payload?.message, { type: "success" });
          state.edited = true;
        } else {
          state.message = payload?.message;
        }
      })
      .addCase(editProduct.rejected, (state) => {
        state.editStatus = "idle";
        state.message = "something went wrong";
      });
  },
});

export default DashboardProductSlice.reducer;
