import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cms_end_points, search_end_point } from "../../Api/Api_End_Points";
import axiosInstance, { getPosition } from "../../Api/AxiosInstance";

export const getPositionSuggestion = createAsyncThunk(
  "search",
  async (data) => {
    try {
      const pos = await getPosition.get(`${data}`);
      return pos.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const search = createAsyncThunk("search", async (data) => {
  try {
    const res = await axiosInstance.post(
      search_end_point.search_with_loaction,
      data
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const fetchAwesomeProducts = createAsyncThunk(
  "fetchAwesomeProducts",
  async (data) => {
    try {
      const res = await axiosInstance.post(cms_end_points.company, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  status: "idle",
  searchData: [],
  searchSuggestion: [],
  fetchAwesomeProducts_status: "idle",
  fetchAwesomeProducts_data: null,
};

const homePageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      //   .addCase(search.pending, (state) => {})
      //   .addCase(search.fulfilled, (state, { payload }) => {})
      //   .addCase(search.rejected, (state) => {})
      .addCase(getPositionSuggestion.pending, (state) => {})
      .addCase(getPositionSuggestion.fulfilled, (state, { payload }) => {
        if (payload?.status === 200) {
          state.searchSuggestion = payload.data;
        }
      })
      .addCase(getPositionSuggestion.rejected, (state) => {})
      //fetch company
      .addCase(fetchAwesomeProducts.pending, (state) => {
        state.fetchAwesomeProducts_status = "loading";
        state.fetchAwesomeProducts_data = null;
      })
      .addCase(fetchAwesomeProducts.fulfilled, (state, { payload }) => {
        state.fetchAwesomeProducts_status = "idle";

        if (payload?.status === 200) {
          state.fetchAwesomeProducts_data = payload.data;
        } else {
          state.fetchAwesomeProducts_data = [];
        }
      })
      .addCase(fetchAwesomeProducts.rejected, (state) => {
        state.fetchAwesomeProducts_status = "idle";
        state.fetchAwesomeProducts_data = [];
      });
  },
});

export default homePageSlice.reducer;
